import React from "react";
import { Grid, Typography, Card } from "@mui/material";

const ReportCmsPdf = ({ data }) => {
    //console.log(data, "report data5")
    return (
        <Grid>
            <Grid container style={{ marginBottom: 10 }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",

                    }}
                >
                    <div>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "center",
                                fontWeight: "bold",
                                maxWidth: "700px",
                                fontSize: "28px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            CMS REPORT
                        </Typography>

                    </div>

                </div>
            </Grid>
            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                Change Request Form
            </h3>
            <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
                <Grid container style={{
                    marginBottom: 10, display: "flex",
                    // alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                }} >
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Date
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.date}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Request Initiator
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.request_initiator}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Department
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.department}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Type of Design Change
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.type_design_change}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Plant
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.plant}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Change Category
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.change_category}
                        </Typography>
                    </div>
                    {
                        data?.change_request[0]?.change_category === "Design Change" ?
                            <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                <Typography
                                    variant="h1"
                                    style={{
                                        textTransform: "uppercase",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        width: "300px",
                                        fontSize: "18px",
                                        color: 'FFFFFF',
                                        padding: '5px'
                                    }}
                                >
                                    Design Change
                                </Typography>
                                <Typography
                                    variant="h1"
                                    style={{
                                        textTransform: "uppercase",
                                        textAlign: "left",
                                        width: "300px",
                                        fontSize: "18px",
                                        color: 'FFFFFF',
                                        padding: '5px'
                                    }}
                                >
                                    {data?.change_request[0]?.design_change}
                                </Typography>
                            </div> :
                            data?.change_request[0]?.change_category === "Process Change" ?
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Change
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_change}
                                    </Typography>
                                </div> : <></>
                    }
                    {
                        data?.change_request[0]?.change_category === "Design Change" && data?.change_request[0]?.design_change === "Internal" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_int_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    {
                        data?.change_request[0]?.change_category === "Design Change" && data?.change_request[0]?.design_change === "External" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.design_ext_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    {
                        data?.change_request[0]?.change_category === "Process Change" && data?.change_request[0]?.process_change === "Internal" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_int_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    {
                        data?.change_request[0]?.change_category === "Process Change" && data?.change_request[0]?.process_change === "External" && (
                            <>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Customer
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_customer}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_model}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Glass Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_glass_reference}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_part_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_part_number_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Part Name
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_part_name}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Drawing Number
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_drawing_number}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Rev No
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_drawing_no_rev_no}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        Process Reference
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            width: "300px",
                                            fontSize: "18px",
                                            color: 'FFFFFF',
                                            padding: '5px'
                                        }}
                                    >
                                        {data?.change_request[0]?.process_ext_process_reference}
                                    </Typography>
                                </div>
                            </>
                        )
                    }

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Description Of Change Required
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.des_change_required}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Others
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.other_comments}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Date of Receiveing Customer Requirement
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.receiveing_customer_date}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Date of Customer Requirement For Implement
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.customer_req_imple_date}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Feasibility Confirmation To Customer
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.feas_con_customer_date}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Remarks
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request[0]?.remarks}
                        </Typography>
                    </div>
                </Grid>
            </Card>

            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                Details of the Change
            </h3>
            <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
                <Grid container style={{
                    marginBottom: 10, display: "flex",
                    // alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                }} >
                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Details of Change Required
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_required_remarks}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                fontWeight: "bold",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            Reason For Change Request
                        </Typography>
                        <Typography
                            variant="h1"
                            style={{
                                textTransform: "uppercase",
                                textAlign: "left",
                                width: "300px",
                                fontSize: "18px",
                                color: 'FFFFFF',
                                padding: '5px'
                            }}
                        >
                            {data?.change_request_remarks}
                        </Typography>
                    </div>
                </Grid>
            </Card>

            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                The Impact of Change
            </h3>
            <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
                <Grid container direction="column" spacing={2}>
                    {data?.impact_change.slice(0, 28).map((impactData, index) => (
                        <Grid item key={index}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: '#000000',
                                            padding: '5px'
                                        }}
                                    >
                                        {/* Assuming you want to show questions related to impactchanges, modify accordingly */}
                                        {/* Add question_no and questions if they exist */}
                                        {impactData.impactchanges.question_no ? `${impactData.impactchanges.question_no}. ` : ""}
                                        {impactData.impactchanges.questions || "No questions available"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontSize: "14px",
                                            color: '#000000',
                                            padding: '5px'
                                        }}
                                    >
                                        Results: {impactData.affects}
                                    </Typography>
                                </Grid>
                                {/* Conditionally render additional details if affects is "Yes" */}
                                {impactData.affects === "Yes" && (
                                    <>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Actions: {impactData.actions}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Responsibility: {impactData.responsibility}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Date: {impactData.date}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Remarks: {impactData.remarks}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Card>

            <h3
                style={{
                    width: "100%",
                    fontWeight: 600,
                    color: "#4D4D4D",
                    padding: "7px 15px",
                    fontSize: 18,
                }}
            >
                Alternation Note
            </h3>
            <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
                <Grid container direction="column" spacing={2}>
                    {data?.alter_note.alter_change.slice(0, 28).map((alterChange, index) => (
                        <Grid item key={index}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: '#000000',
                                            padding: '5px'
                                        }}
                                    >
                                        {alterChange.alterchanges.question_no ? `${alterChange.alterchanges.question_no}. ` : ""}
                                        {alterChange.alterchanges.questions || "No questions available"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                            fontSize: "14px",
                                            color: '#000000',
                                            padding: '5px'
                                        }}
                                    >
                                        Results: {alterChange.affects}
                                    </Typography>
                                </Grid>
                                {/* Conditionally render additional details if affects is "Yes" */}
                                {alterChange.affects === "Yes" && (
                                    <>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                From: {alterChange.from}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                To: {alterChange.to}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Date: {alterChange.date}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Stock Disposal: {alterChange.stock_disposal}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    textTransform: "uppercase",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: '#000000',
                                                    padding: '5px'
                                                }}
                                            >
                                                Remarks: {alterChange.remarks}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ))}

                    <Grid item>
                        <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                This alternation to take effect from :
                            </Typography>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                {data?.alternation_date}
                            </Typography>
                        </div>

                        <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                Date of implementation of change :
                            </Typography>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                {data?.implementation_date}
                            </Typography>
                        </div>

                        <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                Implementation status :
                            </Typography>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                {data?.implementation_status}
                            </Typography>
                        </div>

                        <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                Final comments if any :
                            </Typography>
                            <Typography
                                variant="h1"
                                style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                    width: "300px",
                                    fontSize: "18px",
                                    color: 'FFFFFF',
                                    padding: '5px'
                                }}
                            >
                                {data?.final_comments}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Card>

        </Grid>
    )
}
export default ReportCmsPdf;
import React, { useState, useEffect } from "react";
import { InputLabel, TextField, Button, CircularProgress, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { useRecoilState } from "recoil";
import { questionsData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";

const AlterQuestionsData = ({ isOpen, onClose }) => {
    const [questionsList, setQuestionsList] = useRecoilState(questionsData);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };
        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopup(setShowPopup, 1000);
                }, 2000);
            }
        };
        handlePopups(showSuccessPopup, setShowSuccessPopup);
    }, [showSuccessPopup]);

    const onHandleChange = (e, field) => {
        const value = e.target.value;
        setQuestionsList(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleAlterQuestionsSaveClick = async () => {
        try {
            const questionsEntries = [];

            if (questionsList.alternation) {
                questionsEntries.push({
                    questions: questionsList.alternation,  // Pass the correct tag (alternation)
                    tag: 'alternation',
                });
            }

            // Send the request to the server for each entry (impact or alternation)
            const responses = await Promise.all(questionsEntries.map(entry => {
                return axios.post(API.INSERT_ALTER_CMS_QUESTIONS, entry, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,  // Add authorization if needed
                    },
                });
            }));

            // Check if all requests were successful
            if (responses.every(response => response.status === 200)) {
                setShowSuccessPopup(true);
                setTimeout(() => {
                    onClose();
                    window.location.reload();
                }, 1000);
            }

        } catch (error) {
            console.error("An error occurred in saving master questionsList:", error);
        }
    };

    const handleAlterQuestionsCancelClick = () => {
        onClose();
    }

    return (
        <>
            <Dialog open={isOpen}
                onClose={onClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="sm">
                <DialogTitle>New Questions</DialogTitle>
                <DialogContent>
                    <div style={{ background: "#F7F7F7", minHeight: "90vh" }}>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Alternation Note
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={questionsList.alternation}
                                onChange={(e) => onHandleChange(e, "alternation")}
                            />
                        </div>

                        {initialLoad ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : showSuccessPopup ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <p>Question added successfully!</p>
                                </div>
                            </div>
                        ) : null}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row", // Keep it as row to align buttons horizontally
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 10
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#fff",
                                    fontSize: 20,
                                    color: "#B1174B",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginBottom: 10,
                                    marginRight: 5,
                                    boxShadow: "none",
                                }}
                                onClick={handleAlterQuestionsSaveClick}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#B1174B",
                                    fontSize: 20,
                                    color: "#fff",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginBottom: 10,
                                    boxShadow: "none",
                                }}
                                onClick={handleAlterQuestionsCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );

};

export default AlterQuestionsData;
import React, { useState, useEffect } from "react";
import Header from "../app/TopAppBar";
import { InputLabel, TextField, MenuItem, Typography, Button, FormControl, Radio, RadioGroup, FormControlLabel, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { conclusionData, editMocData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";
import EmailPopup from "./EmailPopup";
import configParam from "../config";
import { handleSend } from "./EmailView";

const conclusion = configParam.CONCLUSION_TYPES;
export default function ConclusionMoc() {
  const [conclu, setConclu] = useRecoilState(conclusionData);
  const [isEditMoc] = useRecoilState(editMocData);
  const [errors, setErrors] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [save, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [showResubmitPopup, setShowResubmitPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [, setMocNumber] = useState(false);
  const [anchorE2, setAnchorE2] = useState(null);
  const navigate = useNavigate();

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
    handlePopups(showSubmitPopup, setShowSubmitPopup);
    handlePopups(showApprovePopup, setShowApprovePopup);
    handlePopups(showRejectPopup, setShowRejectPopup);
    handlePopups(showResubmitPopup, setShowResubmitPopup);
  }, [showSuccessPopup, showUpdatePopup, showSubmitPopup, showApprovePopup, showRejectPopup, showResubmitPopup]);

  const userInfo = JSON.parse(localStorage.getItem("allUsersData"));
  let qualityHeadName = '';
  if (conclu.conclusion === 'Quality Head' || conclu.conclusion === 'Safety & Quality') {
    if (userInfo && userInfo.length > 0) {
      const qualityHeadUser = userInfo.find(user => user.role === 'Quality Head');
      if (qualityHeadUser) {
        qualityHeadName = qualityHeadUser.name;
      }
    }
  }

  let plantHeadName = '';
  if (userInfo && userInfo.length > 0) {
    const plantHeadUser = userInfo.find(user => user.role === 'Plant Head');
    if (plantHeadUser) {
      plantHeadName = plantHeadUser.name;
    }
  }

  let safetyHeadName = '';
  if (conclu.conclusion === 'Safety Head' || conclu.conclusion === 'Safety & Quality') {
    if (userInfo && userInfo.length > 0) {
      const safetyHeadUser = userInfo.find(user => user.role === 'Safety Head');
      if (safetyHeadUser) {
        safetyHeadName = safetyHeadUser.name;
      }
    }
  }

  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const userEmail = storedUserData.MailId;
  const userSGID = storedUserData.SGID;
  const sponsorName = storedUserData.N1FName + " " + storedUserData.N1LName;
  const userName = storedUserData.FName + " " + storedUserData.LName;
  const userRole = storedUserData.role;
  const mocNumberData = localStorage.getItem("mocNumber");
  const updateMocDet = localStorage.getItem("mocID");
  const takenMocID = localStorage.getItem("takeMocID");
  let disableCondition;
  let mocAdminName;
  let disablePlant;
  if (isEditMoc) {
    disableCondition = ((userSGID === mocDetData?.mocForm?.user_id)&&mocDetData?.mocForm?.conclusion===null) ? false : true;
    mocAdminName = mocDetData?.mocForm?.authority_name;
    disablePlant = (userSGID === mocDetData?.mocForm?.user_id
      //  && !(userRole === "Quality Head" || userRole === "Safety Head" || userRole === "Plant Head")
    ) || storedUserData.name === mocDetData?.mocForm?.plant_name ? true : false;
  }

  const handleConclusionSaveClick = async () => {
    try {
      if (!conclu.conclusion) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const requestformID = localStorage.getItem("requestformID");
      const assessmentID = localStorage.getItem("assessmentID");

      const mocNumber = await generateMOCNumber();
      localStorage.setItem("mocNumber", mocNumber);
      const sponsorName = storedUserData.N1FName + " " + storedUserData.N1LName;
      const userSGID = updateMocDet ? storedUserData.SGID : "";
      const admin_sgid = storedUserData.N1SGID;
      const userName = storedUserData.FName + " " + storedUserData.LName;
      const userEmail = storedUserData?.MailId;
      const authorityEmail = storedUserData?.N1MailId;
      const mocData = {
        id: updateMocDet,
        user_id: userSGID,
        updated_at: new Date(),
        created_by: userName,
        createdby_email: userEmail,
        doc_ref: "SGI-IMS-FOR-19",
        rev_date: new Date(),
        request_form_id: requestformID,
        assessment_id: assessmentID,
        conclusion: conclu.conclusion,
        authority_name: sponsorName,
        authorityname_email: authorityEmail,
        designation: conclu.designation,
        admin_remarks: conclu.admin_remarks,
        submission_status: "pending",
        moc_no: mocNumber,
        admin: admin_sgid,
        quality_name: qualityHeadName,
        quality_remarks: conclu.quality_remarks,
        plant_check: conclu.plant_check,
        plant_name: plantHeadName,
        plant_remarks: conclu.plant_remarks,
        safety_name: safetyHeadName,
        safety_remarks: conclu.safety_remarks
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_FORM}?id=${updateMocDet}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setMocNumber(mocResponse.data.moc_no);
        await updateMocApprovals(updateMocDet);
        setSave(true);
        setShowSuccessPopup(true);
        setLoading(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const updateMocApprovals = async () => {
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : "";
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updateMocDet,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        status: "pending",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  useEffect(() => {
    if (isEditMoc) {
      const getConclusion = async () => {
        const storedMocDataString = localStorage.getItem("mocDataByID");
        const storedMocData = JSON.parse(storedMocDataString);
        const updateMocDet = storedMocData.moc_id;
        try {
          const response = await axios.get(
            `${API.GET_MOC_FORM}?id=${updateMocDet}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            });
          const conclusionDetails = await response.data;
          setConclu(conclusionDetails);
        } catch (error) {
          console.error("Error fetching MOC details:", error);
        }
      };
      getConclusion();
    }
  }, [setConclu, isEditMoc]);

  async function getLatestRecord() {
    try {
      const response = await axios.get(`${API.GET_LATEST_MOC}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.data && response.data.length > 0) {
        return response.data[0].moc_no; // Return the latest MOC number
      } else {
        //console.log("No data found in the response");
        return null;
      }
    } catch (error) {
      //console.log("Error:", error);
      return null;
    }
  }

  async function generateMOCNumber() {
    const latestRecord = await getLatestRecord();
    let lastTwoDigits = 1; // Default value if no records are found
    if (latestRecord) {
      const lastMocNumber = parseInt(latestRecord.split("-")[3]); // Extract last three digits
      lastTwoDigits = lastMocNumber + 1;
    }
    const date = new Date();
    const year = date.getFullYear().toString();
    const functions = localStorage.getItem("function_code");
    const yearMocNumber = `MOC-${functions}-${year}-${lastTwoDigits
      .toString()
      .padStart(2, "0")}`;
    return yearMocNumber;
  }
  generateMOCNumber()
    .then((serialNumber) => {
      //console.log("Generated Serial Number:", serialNumber);
    })
    .catch((error) => {
      console.error("Error occurred:", error);
    });


  let from_email = [];
  let to_email = [];
  function getEmailsOfQualityHeads(allUsersData) {
    const qualityHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Quality Head") {
        qualityHeadEmails.push(user.email);
      }
    });

    return qualityHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const qualityHeadEmails = getEmailsOfQualityHeads(userInfo);

  function getEmailsOfSafetyHeads(allUsersData) {
    const safetyHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Safety Head") {
        safetyHeadEmails.push(user.email);
      }
    });

    return safetyHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const safetyHeadEmails = getEmailsOfSafetyHeads(userInfo);

  function getEmailsOfPlantHeads(allUsersData) {
    const plantHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Plant Head") {
        plantHeadEmails.push(user.email);
      }
    });

    return plantHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const plantHeadEmails = getEmailsOfPlantHeads(userInfo);

  if (storedUserData) {
    from_email = [storedUserData?.MailId]
    to_email = [];
    if (mocDetData?.mocForm?.conclusion === "Quality Head") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

    }
    else if (mocDetData?.mocForm?.conclusion === "Safety Head") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.safety_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.safety_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

    }
    else if (mocDetData?.mocForm?.conclusion === "Non Critical") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

    }
    else if (mocDetData?.mocForm?.conclusion === "Safety & Quality") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.safety_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

    }
  }
  else {
    //console.log('storedUserData is empty');
  }

  const handleConclusionSubmitClick = async () => {
    try {
      if (!conclu.conclusion) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return true;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const status = await handleSend(from_email, to_email);
      console.log("status",status)
      if (status === 200) {
        setTimeout(() => {
          navigate("/ManageMoc");
        }, 4000);
        setShowSubmitPopup(true);
      } else {
        setSaveMessage("Failed to save. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
    // if (!anchorE2) {
    //   setAnchorE2(document.body);
    // }
  };

  const handleConclusionReSubmitClick = async () => {
    if (!conclu.conclusion) {
      setErrors(true);
      setStepError(true);
      setSaveMessage(false);
      return true;
    } else {
      setErrors(false);
      setStepError(false);
      setSaveMessage(false);
    }
    const mocData = {
      id: takenMocID,
      admin_remarks: conclu.admin_remarks,
      plant_check: conclu.plant_check,
      submission_status: "resubmitted",
    };
    const mocResponse = await axios.put(
      `${API.UPDATE_MOC_FORM}?id=${takenMocID}`,
      mocData,
      {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      }
    );
    if (mocResponse.status === 200) {
      setShowResubmitPopup(true);
      await insertMocApprovals("resubmitted", takenMocID);
      const status = await handleSend(from_email, to_email);
      if (status === 200) {
        setTimeout(() => {
          navigate("/ManageMoc");
        }, 4000);
      } else {
        setSaveMessage("Failed to save. Please try again.");
      }
    }
    // if (!anchorE2) {
    //   setAnchorE2(document.body);
    // }
  };

  const handleConNextClick = () => {
    navigate("/ManageMoc");
  }

  const onHandleChange = (e, fieldName) => {
    const inputValue = e.target.value;
    setErrors(false);
    setConclu((prevConclu) => ({
      ...prevConclu,
      [fieldName]: inputValue,
    }));
  };

  //console.log("concluw",conclu)

  const handleConclusionApproveClick = async () => {
    const admin = mocDetData?.mocForm?.admin;
    try {
      if (
        conclu.conclusion === "Quality Head" && (storedUserData.name === qualityHeadName ? !conclu.plant_check : false) && conclu.admin_approval_status===true
          || conclu.conclusion === "Safety Head" && (storedUserData.name === safetyHeadName ? !conclu.plant_check : false) && conclu.admin_approval_status===true
            || conclu.conclusion === "Safety & Quality" && (storedUserData.name === qualityHeadName || storedUserData.name === safetyHeadName ? !conclu.plant_check : false)&& conclu.admin_approval_status===true
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const mocData = {
        id: takenMocID,
        admin_remarks: conclu.admin_remarks,
        quality_remarks: conclu.quality_remarks,
        safety_remarks: conclu.safety_remarks,
        plant_check: conclu.plant_check,
        plant_remarks: conclu.plant_remarks,
        submission_status: "approved",
        admin_approval_status: userSGID === admin ? true : mocDetData?.mocForm?.admin_approval_status,
        quality_approval_status: storedUserData.name === qualityHeadName && mocDetData?.mocForm?.admin_approval_status ? true : mocDetData?.mocForm?.quality_approval_status,
        safety_approval_status: storedUserData.name === safetyHeadName && mocDetData?.mocForm?.admin_approval_status ? true : mocDetData?.mocForm?.safety_approval_status,
        plant_approval_status: storedUserData.name === plantHeadName && mocDetData?.mocForm?.admin_approval_status ? true : mocDetData?.mocForm?.plant_approval_status
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_FORM}?id=${takenMocID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setShowApprovePopup(true);
        await insertMocApprovals("approved", takenMocID);
        const status = await handleSend(from_email, to_email);
        if(status===200){
          setTimeout(() => {
            navigate("/ManageMoc");
          }, 4000);
        }
        }
     
      // if (!anchorE2) {
      //   setAnchorE2(document.body);
      // }
    } catch (error) {
      console.error("An error occurred in handleConclusionApproveClick:", error);
    }
  };

  const handleConclusionRejectClick = async () => {
    try {
      if (
        conclu.conclusion === "Quality Head" &&( storedUserData.name === qualityHeadName ? !conclu.plant_check : false) && conclu.admin_approval_status===true
          || conclu.conclusion === "Safety Head" &&( storedUserData.name === safetyHeadName ? !conclu.plant_check : false) && conclu.admin_approval_status===true
            || conclu.conclusion === "Safety & Quality" && (storedUserData.name === qualityHeadName || storedUserData.name === safetyHeadName ? !conclu.plant_check : false)&& conclu.admin_approval_status===true
      ) {
        setErrors(true);
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setErrors(false);
        setStepError(false);
        setSaveMessage(false);
      }
      const mocData = {
        id: takenMocID,
        admin_remarks: conclu.admin_remarks,
        quality_remarks: conclu.quality_remarks,
        safety_remarks: conclu.safety_remarks,
        plant_check: conclu.plant_check,
        plant_remarks: conclu.plant_remarks,
        submission_status: "rejected",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_FORM}?id=${takenMocID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setShowRejectPopup(true);
        await insertMocApprovals("rejected", takenMocID);
        const status = await handleSend(from_email, to_email);
        if(status===200){
          setTimeout(() => {
          navigate("/ManageMoc");
        }, 4000);
        }
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      console.error("An error occurred in handleConclusionRejectClick:", error);
    }
  };

  const insertMocApprovals = async (approvalStatus, takenMocID) => {
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const userAppName = storedUserData.FName + " " + storedUserData.LName;
    try {
      const mocData = {
        moc_id: takenMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        status: approvalStatus
      };
      const mocResponse = await axios.post(API.INSERT_MOC_APPROVALS, mocData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (mocResponse.status === 200) {
        const mocApprovalID = mocResponse.data["identifiers"][0].id;
        localStorage.setItem("mocApprovalID", mocApprovalID);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const handleRadioChange = (answer) => {
    setConclu((prev) => ({
      ...prev,
      plant_check: answer,
    }));
    setErrors(false);
  };

  const handleCloseEmailPopup = () => {
    setAnchorE2(null);
  }

  return (
    <div>
      {/* <Header pageTitle="Conclusion" /> */}
      <div style={{ marginTop: "10px" }}>
        <InputLabel
          style={{
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            marginBottom: 5,
            color: "#000000",
          }}
        >
          Conclusion *
        </InputLabel>
        <TextField
          style={{ width: "100%", marginTop: 5, textAlign: "left" }}
          value={conclu.conclusion}
          onChange={(e) => onHandleChange(e, "conclusion")}
          id="outlined-select-currency"
          select
          InputLabelProps={{
            shrink: true,
          }}
          error={stepError && !conclu.conclusion ? true : false}
          disabled={disableCondition}
        >
          {conclusion.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{ marginTop: "10px" }}>
        <InputLabel
          style={{
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            marginBottom: 5,
            marginTop: "10px",
            color: "#000000",
          }}
        >
          Approving Authority Name (N+1) *
        </InputLabel>
        <TextField
          style={{ width: "100%", background: "#FFFFFF" }}
          value={isEditMoc ? mocAdminName : sponsorName}
          id="name"
          variant="outlined"
          InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
          disabled={true}
        />
      </div>
      {/* <div style={{ marginTop: "10px" }}>
        <InputLabel
          style={{
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            marginBottom: 5,
            marginTop: "10px",
            color: "#000000",
          }}
        >
          CFT Team
        </InputLabel>
        <TextField
          style={{ width: "100%", background: "#FFFFFF" }}
          value={conclu.cft_team}
          onChange={(e) => onHandleChange(e, "cft_team")}
          id="outlined-multiline-static"
          multiline
          rows={4}
          InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
        />
      </div> */}

      {
        isEditMoc ? (
          <div style={{ marginTop: "10px" }}>
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                color: "#000000",
              }}
            >
              (N+1) Remarks
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              value={conclu.admin_remarks}
              onChange={(e) => onHandleChange(e, "admin_remarks")}
              id="outlined-multiline-static"
              multiline
              rows={4}
              error={stepError && !conclu.admin_remarks ? true : false}
              disabled={isEditMoc && (userSGID === mocDetData?.mocForm?.admin && mocDetData?.mocForm?.admin_approval_status === false ? false : true)}
            />
          </div>
        ) : (
          <></>
        )
      }

      {
        (isEditMoc && mocDetData?.mocForm?.admin_approval_status === true) && (
          conclu.conclusion === "Quality Head" ? (
            <div>
              <div style={{ marginTop: "10px" }}>
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: 5,
                    marginTop: "10px",
                    color: "#000000",
                  }}
                >
                  Approving Authority Name (Quality Head) *
                </InputLabel>
                <TextField
                  style={{ width: "100%", background: "#FFFFFF" }}
                  value={qualityHeadName}
                  id="name"
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                  disabled={true}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    color: "#000000",
                  }}
                >
                  (Quality Head) Remarks
                </InputLabel>
                <TextField
                  style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                  value={conclu.quality_remarks}
                  onChange={(e) => onHandleChange(e, "quality_remarks")}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.quality_name && mocDetData?.mocForm?.quality_approval_status === false ? false : true)}
                />
              </div>
              <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={conclu.plant_check}
                  onChange={(event) =>
                    handleRadioChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
              {conclu.plant_check === "Yes" &&
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        marginBottom: 5,
                        marginTop: "10px",
                        color: "#000000",
                      }}
                    >
                      Approving Authority Name (Plant Head) *
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", background: "#FFFFFF" }}
                      value={plantHeadName}
                      id="name"
                      variant="outlined"
                      InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                      disabled={true}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        color: "#000000",
                      }}
                    >
                      (Plant Head) Remarks
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                      value={conclu.plant_remarks}
                      onChange={(e) => onHandleChange(e, "plant_remarks")}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.plant_name && mocDetData?.mocForm?.plant_approval_status === false ? false : true)}
                    />
                  </div>
                </div>
              }
            </div>
          ) : (
            conclu.conclusion === "Safety Head" ? (
              <div>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      marginBottom: 5,
                      marginTop: "10px",
                      color: "#000000",
                    }}
                  >
                    Approving Authority Name (Safety Head) *
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", background: "#FFFFFF" }}
                    value={safetyHeadName}
                    id="name"
                    variant="outlined"
                    InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                    disabled={true}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    (Safety Head) Remarks
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                    value={conclu.safety_remarks}
                    onChange={(e) => onHandleChange(e, "safety_remarks")}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.safety_name && mocDetData?.mocForm?.safety_approval_status === false ? false : true)}
                  />
                  <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                    <Typography>
                      Whether Plant Head approval required *
                    </Typography>
                    <RadioGroup
                      value={conclu.plant_check}
                      onChange={(event) =>
                        handleRadioChange(event.target.value)
                      }
                      row
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio sx={{ color: "#B51849" }} />}
                        label="Yes"
                        disabled={disablePlant}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio sx={{ color: "#B51849" }} />}
                        label="No"
                        disabled={disablePlant}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {conclu.plant_check === "Yes" &&
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      <InputLabel
                        style={{
                          fontWeight: 500,
                          fontStyle: "normal",
                          fontFamily: "Inter, sans-serif",
                          marginBottom: 5,
                          marginTop: "10px",
                          color: "#000000",
                        }}
                      >
                        Approving Authority Name (Plant Head) *
                      </InputLabel>
                      <TextField
                        style={{ width: "100%", background: "#FFFFFF" }}
                        value={plantHeadName}
                        id="name"
                        variant="outlined"
                        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                        disabled={true}
                      />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <InputLabel
                        style={{
                          fontWeight: 500,
                          fontStyle: "normal",
                          fontFamily: "Inter, sans-serif",
                          color: "#000000",
                        }}
                      >
                        (Plant Head) Remarks
                      </InputLabel>
                      <TextField
                        style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                        value={conclu.plant_remarks}
                        onChange={(e) => onHandleChange(e, "plant_remarks")}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.plant_name && mocDetData?.mocForm?.plant_approval_status === false ? false : true)}
                      />
                    </div>
                  </div>
                }
              </div>
            ) : (
              conclu.conclusion === "Safety & Quality" ? (
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        marginBottom: 5,
                        marginTop: "10px",
                        color: "#000000",
                      }}
                    >
                      Approving Authority Name (Quality Head) *
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", background: "#FFFFFF" }}
                      value={qualityHeadName}
                      id="name"
                      variant="outlined"
                      InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                      disabled={true}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        color: "#000000",
                      }}
                    >
                      (Quality Head) Remarks
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                      value={conclu.quality_remarks}
                      onChange={(e) => onHandleChange(e, "quality_remarks")}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.quality_name && mocDetData?.mocForm?.quality_approval_status === false ? false : true)}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        marginBottom: 5,
                        marginTop: "10px",
                        color: "#000000",
                      }}
                    >
                      Approving Authority Name (Safety Head) *
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", background: "#FFFFFF" }}
                      value={safetyHeadName}
                      id="name"
                      variant="outlined"
                      InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                      disabled={true}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        color: "#000000",
                      }}
                    >
                      (Safety Head) Remarks
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                      value={conclu.safety_remarks}
                      onChange={(e) => onHandleChange(e, "safety_remarks")}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.safety_name && mocDetData?.mocForm?.safety_approval_status === false ? false : true)}
                    />
                  </div>
                  <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                    <Typography>
                      Whether Plant Head approval required *
                    </Typography>
                    <RadioGroup
                      value={conclu.plant_check}
                      onChange={(event) =>
                        handleRadioChange(event.target.value)
                      }
                      row
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio sx={{ color: "#B51849" }} />}
                        label="Yes"
                        disabled={disablePlant}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio sx={{ color: "#B51849" }} />}
                        label="No"
                        disabled={disablePlant}
                      />
                    </RadioGroup>
                  </FormControl>
                  {conclu.plant_check === "Yes" &&
                    <div>
                      <div style={{ marginTop: "10px" }}>
                        <InputLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontFamily: "Inter, sans-serif",
                            marginBottom: 5,
                            marginTop: "10px",
                            color: "#000000",
                          }}
                        >
                          Approving Authority Name (Plant Head) *
                        </InputLabel>
                        <TextField
                          style={{ width: "100%", background: "#FFFFFF" }}
                          value={plantHeadName}
                          id="name"
                          variant="outlined"
                          InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <InputLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontFamily: "Inter, sans-serif",
                            color: "#000000",
                          }}
                        >
                          (Plant Head) Remarks
                        </InputLabel>
                        <TextField
                          style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                          value={conclu.plant_remarks}
                          onChange={(e) => onHandleChange(e, "plant_remarks")}
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.plant_name && mocDetData?.mocForm?.plant_approval_status === false ? false : true)}
                        />
                      </div>
                    </div>
                  }
                </div>
              ) : null
            )
          )
        )
      }

      {
        errors ? (
          <Typography variant="subtitle2" color="red">
            {""}
            Please fill all the fields
          </Typography>
        ) : null
      }
      {
        saveMessage ? (
          <Typography variant="subtitle2" color="red">
            {""}
            Please Save before going to Submit
          </Typography>
        ) : null
      }
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          alignItems: "center"
        }}
      >
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showSuccessPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>Data saved successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showUpdatePopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>Data updated successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showSubmitPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC No: {mocNumberData} created successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showApprovePopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Conclusion Approved Successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showRejectPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Conclusion Rejected Successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showResubmitPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Conclusion Resubmitted Successfully!</p>
            </div>
          </div>
        ) : null}
        {!isEditMoc || (isEditMoc && userSGID === mocDetData?.mocForm?.user_id && mocDetData?.mocForm?.submission_status === "pending" && (mocDetData?.mocForm?.moc_no === null || mocDetData?.mocForm?.moc_no === "")) ?
          <>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#fff",
                fontSize: 20,
                color: "#B1174B",
                border: "1px solid #B1174B",
                textTransform: "none",
                width: "75%",
                marginTop: 150,
                marginRight: 5,
                marginBottom: 10,
                boxShadow: "none",
              }}
              onClick={handleConclusionSaveClick}>
              Save
            </Button>
          </>
          :
          isEditMoc && userSGID === mocDetData?.mocForm?.user_id ?
            <></> :
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#fff",
                  fontSize: 20,
                  color: "#B1174B",
                  border: "1px solid #B1174B",
                  textTransform: "none",
                  width: "75%",
                  marginTop: 20,
                  marginRight: 5,
                  marginBottom: 10,
                  boxShadow: "none",
                }}
                onClick={handleConclusionRejectClick}
                disabled={mocDetData?.mocForm?.submission_status === "rejected"}
              >
                Reject
              </Button>
              {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
            </>
        }
        {(isEditMoc && userSGID === mocDetData?.mocForm?.user_id && mocDetData?.mocForm?.moc_no)
          //  && !(userRole === "Quality Head" || userRole === "Safety Head" || userRole === "Plant Head")
          ?
          <>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "75%",
                marginTop: 20,
                marginRight: 5,
              }}
              onClick={mocDetData?.mocForm?.submission_status === "rejected" && mocDetData?.mocForm?.moc_no ? handleConclusionReSubmitClick : handleConNextClick}
              disabled={isEditMoc && mocDetData?.mocForm?.submission_status === "resubmitted"}
            >
              {mocDetData?.mocForm?.submission_status === "rejected" && mocDetData?.mocForm?.moc_no ? "Resubmit" : "Cancel"}
            </Button>
            {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />}
          </>
          :
          !isEditMoc || (isEditMoc && userSGID === mocDetData?.mocForm?.user_id
            // && !(userRole === "Quality Head" || userRole === "Safety Head" || userRole === "Plant Head")
          ) ?
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#B1174B",
                  fontSize: 20,
                  color: "white",
                  textTransform: "none",
                  width: "75%",
                  marginTop: 150,
                  marginRight: 5,
                  marginBottom: 10,
                }}
                onClick={handleConclusionSubmitClick}>
                Submit
              </Button>
              {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
            </>
            :
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#B1174B",
                  fontSize: 20,
                  color: "white",
                  textTransform: "none",
                  width: "75%",
                  marginTop: 20,
                  marginRight: 5,
                  marginBottom: 10,
                }}
                onClick={handleConclusionApproveClick}
                disabled={mocDetData?.mocForm?.submission_status === "rejected"}
              >
                Approve
              </Button>
              {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
            </>
        }
      </div>
    </div>
  );
}
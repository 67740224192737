import React, { useState, useEffect, useRef } from "react";
import Header from "../app/TopAppBar";
import { Tabs, Tab, Card, CardContent, Divider, Typography, Chip, Tooltip, Popover, Grid, Box, Button, Accordion, AccordionDetails, AccordionSummary, Container, Checkbox, TextField, CircularProgress, AvatarGroup, Avatar, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import menu from "../Asset/Icons/menu_managemoc.svg";
import Edit from "../Asset/Icons/edit.svg";
import SearchIcon from "../Asset/Icons/search_icon.svg";
import FilterIcon from "../Asset/Icons/filter_icon.svg";
import CloseIcon from "../Asset/Icons/close_icon.svg";
import Delete from "../Asset/Icons/delete.svg";
import { useNavigate } from "react-router-dom";
import API from "../Services/API";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useRecoilState } from "recoil";
import { getMocDataID, editMocData, requestFormData, changeAssessmentData, conclusionData, userData } from "../recoil/atoms";
import TimeLine from "../Asset/Icons/Arrows.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteDialog from "../settings/DeleteDialog";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PreviewPdf from "./ReportPdf";
import DownloadIcon from '@mui/icons-material/Download';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RequestForm from "./RequestForm";
import PlusIcon from "../Asset/Icons/addnew_icon.svg";
import NewRequest from "./NewRequest";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ManageMoc = ({ isNewUser }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [mocsData, setMocsData] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [pdfmenuPosition, setpdfMenuPosition] = useState({ top: 0, left: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [showPdfMenu, setShowPdfMenu] = useState(false);
  const [mocDataID, setMocDataID] = useRecoilState(getMocDataID);
  const [mocPdfData, setMocPdfData] = useState([]);
  const [, setIsEditMoc] = useRecoilState(editMocData);
  const [recordData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [conclusionAccordionExpand, setConclusionAccordionExpand] = useState(false);
  const [dateAccordionExpand, setDateAccordionExpand] = useState(false);
  const [conclusionCheckbox, setConclusionCheckbox] = useState([
    { id: 1, name: "Critical to Quality", value: "Quality Head", checked: false },
    { id: 2, name: "Critical to Safety", value: "Safety Head", checked: false },
    { id: 3, name: "Critical to Safety & Quality", value: "Safety & Quality", checked: false },
    { id: 4, name: "Non Critical", value: "Non Critical", checked: false },
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [fromDate, setFromDate] = useState([]);
  const [toDate, setToDate] = useState([]);
  const [visibleRecords, setVisibleRecords] = useState(10);
  const [filteredSearchRecords, setFilteredSearchRecords] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [filterCardData, setFilterCardData] = useState([]);
  const [filterProgressData, setFilterProgressData] = useState([]);
  const [filterDraftData, setFilterDraftData] = useState([]);
  const [filterRejectData, setFilterRejectData] = useState([]);
  const [filterApprovalData, setFilterApprovalData] = useState([]);
  const [filterCompleteData, setFilterCompleteData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [draftData, setDraftData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [, setApprovalMocID] = useState([]);
  const openFilterPopover = Boolean(anchorEl);
  const [showActionPlan, setShowActionPlan] = useState([]);
  const [showImplement, setShowImplement] = useState([]);
  const [deleteMocId, setDeleteMocId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [, setApproved] = useState('');
  const [, setPending] = useState('');
  const [, setRejected] = useState('');
  const [, setResubmitted] = useState('');
  const [conclusionTimeline, setConclusionTimeline] = useState([]);
  const [actionTimeline, setActionTimeline] = useState([]);
  const [implementTimeline, setImplementTimeline] = useState([]);
  const filterId = openFilterPopover ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);
  const menuRef = useRef(null);
  const printRef = useRef();
  const [, setRequestForm] = useRecoilState(requestFormData);
  const [, setConclu] = useRecoilState(conclusionData);
  const [, setUserList] = useRecoilState(userData);
  const [, setChangeAssessment] = useRecoilState(changeAssessmentData);
  const [newDialogRequestOpen, setNewDialogRequestOpen] = useState(false);
  const [openedMenuId, setOpenedMenuId] = useState(null);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  //console.log("usersgid",storedUserData)

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const printCover = () => {
    setTimeout(() => {
      handlePrint();
    }, 400);
  }
  useEffect(() => {
    //console.log("editDialogOpeneditDialogOpen", editDialogOpen)
  }, [editDialogOpen])
  const portraitOrientation = () => {
    return (
      <style type="text/css">
        {"@media print{@page {size: landscape; margin: 5mm}}"}
      </style>
    )
  }
  const handlePDF = () => {
    setPreviewForm(true);
    printCover();
  }

  const toggleAccordionTimeline = async (event, id) => {
    const response = await axios.get(
      `${API.GET_APPROVAL_BYMOCID}?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
    const appMocID = response.data;
    const withActionPlanWithoutImplement = [];
    const withoutActionPlanWithoutImplement = [];
    const withActionPlanWithImplement = [];

    appMocID.forEach(item => {
      if (item.actionplan_id && !item.implement_id) {
        withActionPlanWithoutImplement.push(item);
      } else if (!item.actionplan_id && !item.implement_id) {
        withoutActionPlanWithoutImplement.push(item);
      } else if (item.actionplan_id && item.implement_id) {
        withActionPlanWithImplement.push(item);
      }
    });
    setConclusionTimeline(withoutActionPlanWithoutImplement)
    setActionTimeline(withActionPlanWithoutImplement)
    setImplementTimeline(withActionPlanWithImplement)
    function groupByStatus(data) {
      const result = {
        approved: new Map(),
        rejected: new Map(),
        pending: new Map(),
        resubmitted: new Map(),
      };

      data.forEach(item => {
        const { id, status, updated_at } = item;
        const updatedAt = new Date(updated_at);

        if (!result[status].has(id) || result[status].get(id).updated_at < updatedAt) {
          result[status].set(id, { ...item, updated_at: updatedAt });
        }
      });

      // Convert Maps to arrays
      return {
        approved: Array.from(result.approved.values()),
        rejected: Array.from(result.rejected.values()),
        pending: Array.from(result.pending.values()),
        resubmitted: Array.from(result.resubmitted.values()),
      };
    }

    const groupedData = groupByStatus(appMocID);
    if (groupedData.approved && groupedData.approved.length > 0) {
      const latestApproved = groupedData.approved.reduce((latest, current) => {
        return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
      });
      setApproved((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
    } else {
      setApproved("");
    }

    if (groupedData.rejected && groupedData.rejected.length > 0) {
      const latestApproved = groupedData.rejected.reduce((latest, current) => {
        return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
      });
      setRejected((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
    } else {
      setRejected("");
    }

    if (groupedData.pending && groupedData.pending.length > 0) {
      const latestApproved = groupedData.pending.reduce((latest, current) => {
        return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
      });
      setPending((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
    } else {
      setPending("");
    }

    if (groupedData.resubmitted && groupedData.resubmitted.length > 0) {
      const latestApproved = groupedData.resubmitted.reduce((latest, current) => {
        return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
      });
      setResubmitted((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
    } else {
      setResubmitted("");
    }
    setApprovalMocID(appMocID);
    setExpandedCardId(expandedCardId === id ? null : id);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setExpandedCardId(null);
    setShowMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowPdfMenu(false);
      }
    };
    if (showPdfMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPdfMenu]);

  const handleMenuClick = async (event, mocId) => {
    try {
      const cardRect = event.currentTarget.getBoundingClientRect();
      const menuTop = cardRect.bottom + window.scrollY - 30;
      const menuLeft = cardRect.left + window.scrollX - 125;
      const response = await axios.get(
        `${API.GET_APPROVAL_MOC_BY_ID}?id=${mocId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const mocDataByID = response.data;
      localStorage.setItem("mocDataByID", JSON.stringify(mocDataByID));
      //console.log(mocDataByID, "mocDataByID265");
      if (mocDataByID?.id) {
        const mocApprovalID = mocDataByID?.id;
        localStorage.setItem("mocApprovalID", mocApprovalID);
      }
      if (mocDataByID?.moc_id) {
        const mocID = mocDataByID?.mocForm?.id;
        localStorage.setItem("mocID", mocID);
      }
      if(mocDataByID?.mocForm?.moc_no){
        localStorage.setItem("mocNumber", mocDataByID?.mocForm?.moc_no);
      }
      if (mocDataByID?.mocForm?.request_form_id) {
        const requestformID = mocDataByID?.mocForm?.request_form_id;
        localStorage.setItem("requestformID", requestformID);
      }
      if (mocDataByID?.mocForm?.assessment_id) {
        const assessmentID = mocDataByID?.mocForm?.assessment_id;
        localStorage.setItem("assessmentID", assessmentID);
      }
      if (mocDataByID?.actionplan_id) {
        const actionPlanID = mocDataByID?.actionplan?.id;
        localStorage.setItem("actionPlanID", actionPlanID);
      }
      if (mocDataByID?.trials_id) {
        const trialsID = mocDataByID?.trials?.id;
        localStorage.setItem("trialsID", trialsID);
      }
      if (mocDataByID?.implement_id) {
        const changeImplementID = mocDataByID?.changeImplementation?.id;
        localStorage.setItem("changeImplementID", changeImplementID);
      }
      setMocDataID(mocId);
      const hasQualityStatus = mocDataByID.mocForm.quality_approval_status === true;
      const hasSafetyStatus = mocDataByID.mocForm.safety_approval_status === true;
      const hasPlantApprovalStatus = mocDataByID.mocForm.plant_check === 'Yes' && mocDataByID.mocForm.plant_approval_status === true;
      const conSafetyQuality = mocDataByID.mocForm.conclusion === 'Safety & Quality';
      const conQuality = mocDataByID.mocForm.conclusion === 'Quality Head';
      const conSafety = mocDataByID.mocForm.conclusion === 'Safety Head';
      const conNonCritical = mocDataByID.mocForm.conclusion === 'Non Critical' && mocDataByID.mocForm.admin_approval_status === true;
      const plantCheckNo = mocDataByID.mocForm.plant_check === 'No';
      const trialsStatus = mocDataByID?.trials_id === null || mocDataByID?.trials_id !== undefined;
      const qualityResponse = conQuality && hasQualityStatus && hasPlantApprovalStatus;
      const safetyResponse = conSafety && hasSafetyStatus && hasPlantApprovalStatus;
      const qualityRes = conQuality && hasQualityStatus && plantCheckNo && trialsStatus;
      const safetyRes = conSafety && hasSafetyStatus && plantCheckNo;
      const qualitySafetyResponse = conSafetyQuality && hasSafetyStatus && hasQualityStatus && hasPlantApprovalStatus;
      const qualitySafetyRes = conSafetyQuality && hasSafetyStatus && hasQualityStatus && plantCheckNo;
      const showActionPlan =
        // isApproved &&
        (
          (qualityResponse) || (safetyResponse) || (qualityRes) || (safetyRes) ||
          (qualitySafetyResponse) || (qualitySafetyRes) || (conNonCritical)
        );
      const showImplement = mocDataByID?.documents_id ? true : false;
      setMenuPosition({ top: menuTop, left: menuLeft });
      setOpenedMenuId(mocId);
      setShowMenu(true);
      setShowActionPlan(showActionPlan);
      setShowImplement(showImplement);
    } catch (error) {
      console.error("Error fetching Moc data by ID:", error);
    }
  };

  const handleDownloadDocuments = async () => {
    const combinedDocReferences = mocPdfData.actionplan_description.reduce((acc, page, index) => {
      //console.log("page",page)
      //console.log("paggeee",`page ${index+1}`)
      //console.log("pagge12",page[`page ${index+1}`])
      if (page[`page ${index + 1}`].doc_reference && Array.isArray(page[`page ${index + 1}`].doc_reference)) {
        acc = acc.concat(page[`page ${index + 1}`].doc_reference);
      }
      return acc;
    }, []);

    //console.log("combinedDocReferences",combinedDocReferences);
    if(combinedDocReferences.length>0){
    const abc = await axios.post(`${API.DOWNLOAD_ALL_DOCUMENTS}`, { filenames: combinedDocReferences }, {
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      responseType: 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([abc.data], { type: 'application/zip' }));

    // Create a temporary link element and trigger a download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'documents.zip'); // Filename for download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  }else{
    alert(`The following Moc Contains zero document to download`);
  }
  }

  const handlePdfMenuClick = async (event, mocId) => {
    try {
      const cardRect = event.currentTarget.getBoundingClientRect();
      const menuTop = cardRect.bottom + window.scrollY - 30;
      const menuLeft = cardRect.left + window.scrollX - 125;
      const response = await axios.get(
        `${API.SHOW_PDF}/${mocId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      const mocDataByID = response.data[0];
      setMocPdfData(mocDataByID);
      // const isApproved = mocDataByID.mocForm.submission_status === "approved";
      setpdfMenuPosition({ top: menuTop, left: menuLeft });
      setShowPdfMenu(true);
    } catch (error) {
      console.error("Error fetching Moc data by ID:", error);
    }
  };

  const editData = async (mocId) => {
    try {
      const response = await axios.get(`${API.EDIT_APPROVAL_MOC}?id=${mocId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      const mocDetails = await response.data;
      localStorage.setItem("mocDetails", JSON.stringify(mocDetails));
      // navigate(`/RequestForm`);
      // setDialogContent(JSON.stringify(mocDetails, null, 2));
      setEditDialogOpen(true);
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
  };

  const actionPlanSet = async () => {
    const actionData = JSON.parse(localStorage.getItem("mocDataByID"));
    const actionplanID = actionData.actionplan_id;
    try {
      if (!actionplanID) {
        const emptyActionPlanDetails = {
          actionplan_description: [],
        };
        localStorage.setItem("emptyActionPlanDetails", JSON.stringify(emptyActionPlanDetails));
        navigate(`/actionSheet`);
        return;
      }
      const response = await axios.get(
        `${API.GET_ACTION_PLAN_BY_ID}?id=${actionplanID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      const actionPlanDetails = await response.data;
      localStorage.setItem("actionPlanDetails", JSON.stringify(actionPlanDetails));
      navigate(`/actionSheet`);
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
  };

  const deleteMOC = async () => {
    try {
      const response = await axios.delete(
        `${API.DELETE_MOC}?id=${mocDataID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      const updatedMocsData = mocsData.filter((moc) => moc.id !== mocDataID);
      setDraftData(updatedMocsData);
      setMocsData(updatedMocsData);
      setShowMenu(false);
      loadMocData();
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
  };

  const implementSet = async () => {
    const implementData = JSON.parse(localStorage.getItem("mocDataByID"));
    const implementID = implementData.implement_id;
    try {
      if (!implementID) {
        const emptyImplementDetails = {
          implement_remarks: [],
          customer_approval: [],
          product_compliance_check: [],
          product_compliance: [],
        };
        localStorage.setItem("emptyImplementDetails", JSON.stringify(emptyImplementDetails));
        navigate(`/implementmoc`);
        return;
      }
      const response = await axios.get(
        `${API.GET_CHANGE_IMPLEMENT}?id=${implementID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      const implementDetails = await response.data;
      localStorage.setItem("implementDetails", JSON.stringify(implementDetails));
      navigate(`/implementmoc`);
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
  };

  // Function to open delete dialog
  const openDeleteConfirmation = (userId) => {
    setDeleteMocId(userId);
    setOpenDeleteDialog(true);
  };

  // Function to close delete dialog
  const closeDeleteConfirmation = () => {
    setOpenDeleteDialog(false);
  };

  const handleMenuItemClick = async (menuItem, moc) => {
    setIsEditMoc(true);
    switch (menuItem) {
      case "edit":
        editData(moc.id);
        break;
      case "actionPlan":
        actionPlanSet(moc.actionplan_id);
        break;
      case "delete":
        openDeleteConfirmation(moc.id);
        break;
      case "implementMOC":
        implementSet(moc.implement_id);
        break;
      default:
        break;
    }
    setShowMenu(false);
  };

  const renderRecords = () => { };

  const user = storedUserData.SGID;

  const approverSGID = storedUserData.admin_sgid;
  const userName = storedUserData.name;
  const userRole = storedUserData.role === "Admin";
  const qualityRole = storedUserData.role === "Quality Head";

  const loadMocData = () => {
    try {
      axios
        .get(API.GET_ALL_MOC_FOR_APPROVALS, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data && response.data.length > 0) {

              const conclusionData = response.data;
              setCardData(conclusionData);
              setFilterCardData(conclusionData);

              const filteredConclusionData = response.data.filter((item) => item.mocForm.conclusion === null && item.mocForm.deleted !== true);
              setDraftData(filteredConclusionData);
              setFilterDraftData(filteredConclusionData);

              const filteredApprovalData = response.data.filter((item) => {
                //console.log(item, "item523")
                const moc = item.mocForm;
                if (
                  moc.conclusion === "Non Critical" &&
                  (
                    (moc.submission_status === "pending" || moc.submission_status === "resubmitted") ||
                    (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted")
                  )
                ) {
                  return true;
                }
                else if (
                  (
                    (moc.submission_status === "pending" && moc.conclusion !== null) ||
                    moc.submission_status === "resubmitted" ||
                    (item?.changeImplementation?.implement_status === "pending" && moc.conclusion !== null) ||
                    item?.changeImplementation?.implement_status === "resubmitted" ||
                    (
                      // (moc.submission_status === "pending" || moc.submission_status === "approved") && (item?.trials === null || item?.trials?.trials_status === "pending" || item?.trials?.trials_status === "resubmitted" || item?.trials?.trials_status === "approved") && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === false)
                      (moc.conclusion === "Quality Head") &&
                      ((moc.submission_status === "pending" || moc.submission_status === "approved") && moc.admin_approval_status === true)
                      && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved"  && item?.changeImplementation?.admin_approval_status === true && ((item?.changeImplementation?.quality_approval_status === true && (item?.changeImplementation?.plant_check==="Yes" && item?.changeImplementation?.plant_approval_status===false) )|| item?.changeImplementation?.quality_approval_status === false)))
                      )
                    ) && user === item.user_sgid

                    // || (moc.conclusion === "Quality Head" && moc?.trials?.trials_status === "pending" || moc?.trials?.trials_status === "resubmitted" && moc?.trials?.admin_approval_status === false) && user === moc.user_sgid
                    ||
                    (
                      (moc.conclusion === "Safety Head") &&
                      ((moc.submission_status === "pending" || moc.submission_status === "approved") && moc.admin_approval_status === true) && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && ((item?.changeImplementation?.quality_approval_status === true && (item?.changeImplementation?.plant_check==="Yes" && item?.changeImplementation?.plant_approval_status===false) ) || item?.changeImplementation?.safety_approval_status === false) && item?.changeImplementation?.plant_approval_status === false))
                      )
                    ) && user === item.user_sgid
                    ||
                    (
                      (moc.conclusion === "Safety & Quality")
                      &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === true)
                      && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === true && item?.changeImplementation?.safety_approval_status === false)
                      ) &&
                      user === item.user_sgid
                      ||
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes")
                      && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === false && item?.changeImplementation?.quality_approval_status === false && item?.changeImplementation?.safety_approval_status === false && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                      ) &&
                      user === item.user_sgid
                      ||
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === false && moc.plant_check === "No") &&
                      user === item.user_sgid
                      // && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                      // )
                    ) &&
                    user === item.user_sgid
                    ||
                    (
                      (moc.conclusion === "Quality Head") &&
                      (moc.submission_status === "pending" || moc.submission_status === "approved") && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted")
                    ) && user === item.approver_sgid
                    ||
                    ((moc.conclusion === "Safety Head") &&
                      (moc.submission_status === "pending" || moc.submission_status === "approved") && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted")
                    ) && user === item.approver_sgid
                    ||
                    (
                      (moc.conclusion === "Safety & Quality")
                      &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === true)
                      && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === true && item?.changeImplementation?.safety_approval_status === false)
                      )
                      ||
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials?.trials_status === "pending" && item?.trials?.admin_approval_status === false && item?.trials?.quality_approval_status === false && item?.trials?.safety_approval_status === false && item?.trials?.plant_approval_status === false)
                      ||
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === true)
                      && ((item?.changeImplementation?.admin_approval_status === false && item?.changeImplementation?.quality_approval_status === false && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                      )
                      ||
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === false && moc.plant_check === "No") && (item?.trials === null || item?.trials?.admin_approval_status === true && item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && item?.trials?.plant_approval_status === false)
                      && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === false && item?.changeImplementation?.quality_approval_status === false && item?.changeImplementation?.plant_approval_status === false && item?.changeImplementation?.safety_approval_status === false)
                      )
                    ) &&
                    user === item.approver_sgid
                    ||
                    (
                      (
                        (moc.conclusion === "Quality Head") &&
                        (moc.submission_status === "approved" && moc.admin_approval_status === true)
                        && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved"))
                        )
                      ) && userName === moc.quality_name
                    )

                    ||
                    (
                      (moc.conclusion === "Safety & Quality") &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true) && (item?.trials === null || item?.trials?.admin_approval_status === true && (item?.trials?.quality_approval_status === false || item?.trials?.quality_approval_status === true || item?.trials?.quality_approval_status === undefined))
                      && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true)
                      )
                    ) && userName === moc.quality_name
                    ||
                    (
                      (moc.conclusion === "Safety Head") &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true) 
                      && ((item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved"))
                      )
                    ) && userName === moc.safety_name
                    ||
                    (
                      (moc.conclusion === "Safety & Quality") &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true) && (item?.trials === null || item?.trials?.admin_approval_status === true && (item?.trials?.safety_approval_status === false || item?.trials?.safety_approval_status === true))
                      && ((item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true)
                      )
                    ) && userName === moc.safety_name
                    ||
                    (
                      (moc.conclusion === "Quality Head") &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.quality_approval_status === true &&( moc.plant_check === "Yes" || item?.changeImplementation?.plant_check==="Yes"))
                     && (item?.changeImplementation === null || (item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved") && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.plant_approval_status === false)
                    ) &&
                    userName === moc.plant_name
                    ||
                    (
                      (moc.conclusion === "Safety Head") &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && moc.safety_approval_status === true &&( moc.plant_check === "Yes" || item?.changeImplementation?.plant_check==="Yes")) && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "pending" || item?.changeImplementation?.implement_status === "resubmitted" || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.safety_approval_status === true && item?.changeImplementation?.plant_approval_status === false)
                    ) && userName === moc.plant_name
                    ||
                    (
                      (moc.conclusion === "Safety & Quality")
                      &&
                      (moc.submission_status === "approved" && moc.admin_approval_status === true && ((moc.quality_approval_status === true && moc.safety_approval_status === true) || (moc.quality_approval_status === true || moc.safety_approval_status === true)) && moc.plant_check === "Yes") && (item?.trials === null || item?.trials?.trials_status === "approved" && item?.trials?.admin_approval_status === true && (item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true) || (item?.trials?.quality_approval_status === true || item?.trials?.safety_approval_status === true)) && (item?.changeImplementation === null || item?.changeImplementation?.implement_status === "approved" && item?.changeImplementation?.admin_approval_status === true && (item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.safety_approval_status === true || (item?.changeImplementation?.quality_approval_status === true || item?.changeImplementation?.safety_approval_status === true)) && item?.changeImplementation?.plant_approval_status === false)
                    )
                    && userName === moc.plant_name
                  )
                ) {
                  return true;
                }
                return false;
              });
              setApprovalData(filteredApprovalData);
              setFilterApprovalData(filteredApprovalData);

              const filteredRejectData = response.data.filter((item) => {
                const moc = item.mocForm;
                // Define isRejected variable based on item properties
                const isRejected = item.mocForm.submission_status === "rejected" ||
                  item?.changeImplementation?.implement_status === "rejected";
                // Check if the item matches the desired conditions
                if (
                  (moc.conclusion === "Quality Head" ||
                    moc.conclusion === "Safety Head" ||
                    moc.conclusion === "Non Critical") &&
                  isRejected
                ) {
                  return true;
                }
                if (
                  moc.conclusion === "Safety & Quality" &&
                  item.mocForm.submission_status === "rejected" || item?.changeImplementation?.implement_status === "rejected" && user === item.user_sgid
                  || moc?.admin_approval_status === false && user === item.approver_sgid
                  || moc?.admin_approval_status === true && moc?.quality_approval_status === false && userName === moc.quality_name
                  || moc?.admin_approval_status === true && moc?.safety_approval_status === false && userName === moc.safety_name
                  || moc?.admin_approval_status === true && moc?.quality_approval_status === true && moc?.safety_approval_status === true && moc?.plant_approval_status === false && userName === moc.plant_name
                  ||
                  item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === false && user === item.approver_sgid
                  || item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === false && userName === moc.quality_name
                  || item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.safety_approval_status === false && userName === moc.safety_name
                  || item?.changeImplementation?.implement_status === "rejected" && item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.quality_approval_status === true && item?.changeImplementation?.safety_approval_status === true && item?.changeImplementation?.plant_approval_status === false && userName === moc.plant_name
                ) {
                  return true;
                }
                return false;
              });
              setRejectedData(filteredRejectData);
              setFilterRejectData(filteredRejectData);
              //console.log(filteredRejectData, "filteredRejectData645")
              //console.log(rejectedData, "rejectedData646")

              const filteredCompletedData = response.data.filter((item) => {
                const moc = item.mocForm;
                //console.log(moc, "moc648")
                if (
                  moc.conclusion === "Non Critical" &&
                  (
                    item?.changeImplementation?.implement_status === "approved" &&
                    item?.changeImplementation?.admin_approval_status === true &&
                    (user === item.user_sgid || user === item.approver_sgid)
                  )
                ) {
                  return true;
                } else if (
                  moc.conclusion === "Quality Head" &&
                  (
                    item?.changeImplementation?.implement_status === "approved" &&
                    ((item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.product_compliance_check === "No")|| (     item?.changeImplementation?.admin_approval_status === true &&
                      item?.changeImplementation?.quality_approval_status === true &&
                      (
                        (item?.changeImplementation?.plant_check === "No") ||
                        (item?.changeImplementation?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === true)
                      )))
               
                    &&
                    (user === item.user_sgid || user === item.approver_sgid || userName === moc.quality_name || userName === moc.plant_name)
                  )
                ) {
                  return true;
                }
                else if (
                  moc.conclusion === "Safety Head" &&
                  (
                    item?.changeImplementation?.implement_status === "approved" &&
                    ((item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.product_compliance_check==="No")||(   item?.changeImplementation?.admin_approval_status === true &&
                      item?.changeImplementation?.safety_approval_status === true &&
                      (
                        (item?.changeImplementation?.plant_check === "No") ||
                        (item?.changeImplementation?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === true)
                      )))
                  &&
                    (user === item.user_sgid || user === item.approver_sgid || userName === moc.safety_name || userName === moc.plant_name)
                  )
                ) {
                  return true;
                }
                else if (
                  moc.conclusion === "Safety & Quality" &&
                  (
                    item?.changeImplementation?.implement_status === "approved" &&
                    item?.changeImplementation?.admin_approval_status === true &&
                    item?.changeImplementation?.quality_approval_status === true &&
                    item?.changeImplementation?.safety_approval_status === true &&
                    (
                      (moc?.plant_check === "No") ||
                      (moc?.plant_check === "Yes" && item?.changeImplementation?.plant_approval_status === true)
                    ) &&
                    (user === item.user_sgid || user === item.approver_sgid || userName === moc.quality_name || userName === moc.safety_name || userName === moc.plant_name)
                  )
                ) {
                  return true;
                } else {
                  return false;
                }
              });
              setCompletedData(filteredCompletedData);
              setFilterCompleteData(filteredCompletedData);
              //console.log(filteredCompletedData, "filteredCompletedData707")

              const filteredProgressData = response.data.filter((item) => {
                const moc = item.mocForm;
                if (
                  moc.conclusion === "Non Critical" &&
                  (
                    moc.admin_approval_status === true
                  )
                ) {
                  return true;
                } else if (
                  moc.conclusion === "Quality Head" &&
                  (
                    (moc.admin_approval_status === true && moc.quality_approval_status === true && user === item.user_sgid) ||
                    (moc.admin_approval_status === true && user === item.approver_sgid) ||
                    (moc.admin_approval_status === true && moc.quality_approval_status === true && userName === moc.quality_name) ||
                    (moc.admin_approval_status === true && moc.quality_approval_status === true && userName === moc.plant_name) 
                  )
                ) {
                  return true;
                } else if (
                  moc.conclusion === "Safety Head" &&
                  (
                    (moc.admin_approval_status === true && moc.safety_approval_status === true && user === item.user_sgid) ||
                    (moc.admin_approval_status === true && user === item.approver_sgid) ||
                    (moc?.plant_check === "No" && moc.admin_approval_status === true && moc.safety_approval_status === true && (item?.implement_id === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.safety_approval_status === false) && userName === moc.safety_name) ||
                    (moc?.plant_check === "Yes" && moc.admin_approval_status === true && moc.safety_approval_status === true && (item?.implement_id === null || item?.changeImplementation?.admin_approval_status === true && item?.changeImplementation?.safety_approval_status === true && item?.changeImplementation?.plant_approval_status === false) && userName === moc.safety_name) ||
                    (moc.admin_approval_status === true && moc.safety_approval_status === true && moc.plant_approval_status === true && userName === moc.plant_name)
                  )
                ) {
                  return true;
                } else if (
                  moc.conclusion === "Safety & Quality" &&
                  (
                    (moc.admin_approval_status === true &&
                      moc.quality_approval_status === true && moc.safety_approval_status === true && user === item.user_sgid) ||
                    (item?.trials?.admin_approval_status === true &&
                      item?.trials?.quality_approval_status === true && item?.trials?.safety_approval_status === true && user === item.user_sgid) ||
                    (moc.admin_approval_status === true && (item?.trials_id === null || item?.trials?.admin_approval_status === true) && (item?.changeImplementation === null || item?.changeImplementation?.admin_approval_status === true) && user === item.approver_sgid) ||
                    (moc.admin_approval_status === true &&
                      moc.quality_approval_status === true && userName === moc.quality_name) ||
                    (moc.admin_approval_status === true && moc.safety_approval_status === true && userName === moc.safety_name) ||
                    (moc.admin_approval_status === true && moc.safety_approval_status === true && moc.quality_approval_status === true && userName === moc.plant_name)
                  )
                ) {
                  return true;
                }
                return false;
              });
              setProgressData(filteredProgressData);
              setFilterProgressData(filteredProgressData);

              const completedMoc = filteredCompletedData.length;
              localStorage.setItem("completedMoc", completedMoc);
              const pendingMoc = filteredApprovalData.length;
              localStorage.setItem("pendingMoc", pendingMoc);
              const resubmittedMoc = filteredProgressData.length;
              localStorage.setItem("resubmittedMoc", resubmittedMoc);
              const rejectedMoc = filteredRejectData.length;
              localStorage.setItem("rejectedMoc", rejectedMoc);
              const allMoc = conclusionData.length;
              localStorage.setItem("allMoc", allMoc);
            }
          }
        })
        .catch(function (error) {
          //console.log("ERROR", error);
        })
        .finally(() => {
          setInitialLoad(false);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(
    () => {
      loadMocData();
    },
    [
      //mocsData
    ]
  );

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    if (selectedTab === 0) {
      filterAllMoc(event.target.value);
    } else if (selectedTab === 1) {
      filterProgress(event.target.value);
    } else if (selectedTab === 2) {
      filterDraft(event.target.value);
    } else if (selectedTab === 3) {
      filterApproval(event.target.value);
    } else if (selectedTab === 4) {
      filterCompleted(event.target.value);
    } else {
      filteredRejected(event.target.value);
    }
  };

  const filterAllMoc = (searchText) => {
    const filtMoc = filterMocData(filterCardData, searchText);
    setFilterCardData(filtMoc);
    if (searchText) {
      setCardData(filtMoc);
    } else {
      loadMocData();
    }
  };
  const filterMocData = (data, search) => {
    const filtMoc = data.filter((moc) => {
      const lowerCaseQuery = search.toLowerCase();
      const conclusion = (moc?.mocForm?.conclusion ? moc?.mocForm?.conclusion : "").toLowerCase().includes(lowerCaseQuery);
      const moc_no = (moc?.mocForm?.moc_no ? moc?.mocForm?.moc_no : "").toLowerCase().includes(lowerCaseQuery);
      const created_at = moment(moc.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
      const change_required = (moc?.mocForm?.requestForm.change_required ? moc?.mocForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
      return conclusion || moc_no || created_at || change_required;
    });
    return filtMoc;
  };

  const filterProgress = (searchText) => {
    const filtProgress = filterProg(filterProgressData, searchText);
    setFilterProgressData(filtProgress);
    if (searchText) {
      setProgressData(filtProgress);
    } else {
      loadMocData();
    }
  };
  const filterProg = (data, search) => {
    const filtProgress = data.filter((moc) => {
      const lowerCaseQuery = search.toLowerCase();
      const conclusion = moc?.mocForm?.conclusion.toLowerCase().includes(lowerCaseQuery);
      const moc_no = (moc?.mocForm?.moc_no ? moc?.mocForm?.moc_no : "").toLowerCase().includes(lowerCaseQuery);
      const created_at = moment(moc.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
      const change_required = (moc?.mocForm?.requestForm.change_required ? moc?.mocForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
      return conclusion || moc_no || created_at || change_required;
    });
    return filtProgress;
  };

  const filterDraft = (searchText) => {
    const filtDraft = filterDraf(filterDraftData, searchText);
    setFilterDraftData(filtDraft);
    if (searchText) {
      setDraftData(filtDraft);
    } else {
      loadMocData();
    }
  };
  const filterDraf = (data, search) => {
    const filtDraft = data.filter((moc) => {
      const lowerCaseQuery = search.toLowerCase();
      const created_at = moment(moc.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
      const change_required = (moc?.mocForm?.requestForm.change_required ? moc?.mocForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
      return created_at || change_required;
    });
    return filtDraft;
  };

  const filterApproval = (searchText) => {
    const filtApproval = filterApprov(filterApprovalData, searchText);
    setFilterApprovalData(filtApproval);
    if (searchText) {
      setApprovalData(filtApproval);
    } else {
      loadMocData();
    }
  };
  const filterApprov = (data, search) => {
    const filtApproval = data.filter((moc) => {
      const lowerCaseQuery = search.toLowerCase();
      const conclusion = moc?.mocForm?.conclusion.toLowerCase().includes(lowerCaseQuery);
      const moc_no = (moc?.mocForm?.moc_no ? moc?.mocForm?.moc_no : "").toLowerCase().includes(lowerCaseQuery);
      const created_at = moment(moc.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
      const change_required = (moc?.mocForm?.requestForm.change_required ? moc?.mocForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
      return conclusion || moc_no || created_at || change_required;
    });
    return filtApproval;
  };

  const filterCompleted = (searchText) => {
    const filtCompleted = filterCompletedData(filterCompleteData, searchText);
    setFilterCompleteData(filtCompleted);
    if (searchText) {
      setCompletedData(filtCompleted);
    } else {
      loadMocData();
    }
  };
  const filterCompletedData = (data, search) => {
    const filtCompleted = data.filter((moc) => {
      const lowerCaseQuery = search.toLowerCase();
      const conclusion = moc?.mocForm?.conclusion.toLowerCase().includes(lowerCaseQuery);
      const moc_no = (moc?.mocForm?.moc_no ? moc?.mocForm?.moc_no : "").toLowerCase().includes(lowerCaseQuery);
      const created_at = moment(moc.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
      const change_required = (moc?.mocForm?.requestForm.change_required ? moc?.mocForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
      return conclusion || moc_no || created_at || change_required;
    });
    return filtCompleted;
  };

  const filteredRejected = (searchText) => {
    const filtRejected = filteredRejectedData(filterRejectData, searchText);
    setFilterRejectData(filtRejected);
    if (searchText) {
      setRejectedData(filtRejected);
    } else {
      loadMocData();
    }
  };
  const filteredRejectedData = (data, search) => {
    const filtRejected = data.filter((moc) => {
      const lowerCaseQuery = search.toLowerCase();
      const conclusion = moc?.mocForm?.conclusion.toLowerCase().includes(lowerCaseQuery);
      const moc_no = (moc?.mocForm?.moc_no ? moc?.mocForm?.moc_no : "").toLowerCase().includes(lowerCaseQuery);
      const created_at = moment(moc.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
      const change_required = (moc?.mocForm?.requestForm.change_required ? moc?.mocForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
      return conclusion || moc_no || created_at || change_required;
    });
    return filtRejected;
  };

  const openFilter = async (event) => {
    setCoordinates([event.pageX, event.pageY]);
    setAnchorEl(event.currentTarget);
  };

  const handleFilter = async () => {
    try {
      setError("");
      setLoading(true);
      handleCloseFilter();
      const filteredNewRecord = await getFilteredNewRecordData();
      if (filteredNewRecord.length === 0) {
        setError("No completed MOC");
      } else {
        setError("");
        setFilteredData(filteredNewRecord);
        setFilterCardData(filteredNewRecord);
        setFilterProgressData(filteredNewRecord);
        setFilterDraftData(filteredNewRecord);
        setFilterRejectData(filteredNewRecord);
        setFilterApprovalData(filteredNewRecord);
        setFilterCompleteData(filteredNewRecord);
        setSnackbarOpen(true);
      }
    } catch (error) {
      //console.log("ERROR", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setRequestForm([]);
    if (setChangeAssessment) {
      setChangeAssessment({
        change_affects: [],
        results: Array(1).fill("#B51849"),
        checkpoints_remarks: Array(1).fill(""),
        environment_results: Array(1).fill(""),
        health_results: Array(1).fill(""),
        safety_results: Array(1).fill(""),
        fire_results: Array(1).fill(""),
        environment_remarks: Array(1).fill(""),
        health_remarks: Array(1).fill(""),
        safety_remarks: Array(1).fill(""),
        fire_remarks: Array(1).fill(""),
      });
    }
    setConclu([]);
    setUserList([]);
    setIsEditMoc(false);
    setNewDialogRequestOpen(true);
    localStorage.removeItem("isRequestSave");
    localStorage.removeItem("isAssessmentSave");
    localStorage.removeItem("isEHSFSave");

  };

  const getFilteredNewRecordData = async () => {
    try {
      const matchedConclusionType = conclusionCheckbox.filter((type) => type.checked);

      const tabs = selectedTab === 0 ? cardData : selectedTab === 1 ? progressData : selectedTab === 2 ? draftData : selectedTab === 3 ? approvalData : selectedTab === 4 ? completedData : selectedTab === 5 ? rejectedData : [];

      const from = new Date(fromDate);
      const to = new Date(toDate);
      to.setHours(23, 59, 59, 999); // Set the end of the toDate to the end of the day

      const finalFilteredData = tabs.filter((record) => {
        const recordCreatedAt = new Date(record.mocForm.created_at);

        if (from && to && (recordCreatedAt < from || recordCreatedAt > to)) {
          return false;
        }

        if (matchedConclusionType.length === 0) {
          return true;
        }

        return matchedConclusionType.some((type) => record.mocForm.conclusion === type.value);
      });
      return finalFilteredData;
    } catch (error) {
      console.error("Error filtering data:", error);
      return [];
    }
  };

  const handleResetFilter = () => {
    setConclusionAccordionExpand(false);
    setFilteredData(recordData);
    setFilterCardData(cardData);
    setFilterProgressData(progressData);
    setFilterDraftData(draftData);
    setFilterRejectData(rejectedData);
    setFilterApprovalData(approvalData);
    setFilterCompleteData(completedData);
    setConclusionCheckbox(conclusionCheckbox.map(item => ({ ...item, checked: false })));
    setAllChecked(false);
    setSelectedOptions([]);
    handleCloseFilter();
    setFromDate("");
    setToDate("");
  };

  const handleAPIClick = async () => {
    try {
      const userDetails = {
        userid: "SG1HR",
        password: "nK+<@P`s7B4R=:Pz",
        secretkey: "<%&Q4km++X:vdM2q",
      }
      const response = await axios.post(API.CHECK_API, userDetails, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.status === 200) {
        //console.log("response", response);
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const closeFilter = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (accordionType) => {
    if (accordionType === "conclusion") {
      setConclusionAccordionExpand(!conclusionAccordionExpand);
    } else if (accordionType === "date") {
      setDateAccordionExpand(!dateAccordionExpand);
    }
  };

  const handleCheckbox = (id) => {
    const updatedCheckboxes = conclusionCheckbox.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setConclusionCheckbox(updatedCheckboxes);
    const allSelected = updatedCheckboxes.every((checkbox) => checkbox.checked);
    setAllChecked(allSelected);
  };

  // Function to update selected filter options
  const handleFilterOptionSelect = (filterOption) => {
    if (selectedOptions.includes(filterOption)) {
      setSelectedOptions(
        selectedOptions.filter((option) => option !== filterOption)
      );
    } else {
      setSelectedOptions([...selectedOptions, filterOption]);
    }
  };

  const handleSelectAll = () => {
    const newAllChecked = !allChecked;
    const updatedCheckboxes = conclusionCheckbox.map((checkbox) => ({
      ...checkbox,
      checked: newAllChecked,
    }));

    setConclusionCheckbox(updatedCheckboxes);
    setAllChecked(newAllChecked);

    // Perform filtering when all checkboxes are selected
    if (newAllChecked) {
      const filteredNewRecord = cardData.filter((record) =>
        updatedCheckboxes.some((type) => record.mocForm.conclusion === type.value)
      );

      setFilteredData(filteredNewRecord);
      setFilterCardData(filteredNewRecord);
      setFilterProgressData(filteredNewRecord);
      setFilterDraftData(filteredNewRecord);
      setFilterRejectData(filteredNewRecord);
      setFilterApprovalData(filteredNewRecord);
      setFilterCompleteData(filteredNewRecord);
    }
  };

  const handleClickSeeMore = () => {
    setVisibleRecords(visibleRecords + 10);
    // setVisibleRecords((prevVisibleRecords) => prevVisibleRecords + 10);
  };

  const displayedRecords = Array.isArray(filteredData)
    ? filteredData
      .filter(
        (record) =>
          record &&
          Array.isArray(record.after_status) &&
          record.after_status !== undefined &&
          record.after_status.length > 0
      )
      .slice(0, visibleRecords)
    : [];
  const totalAllMocRecords = cardData.reduce((acc, record) => acc + 1, 0);
  const totalProgressRecords = progressData.reduce((acc, record) => acc + 1, 0);
  const totalRejectedRecords = rejectedData.reduce((acc, record) => acc + 1, 0);
  const totalApprovalRecords = approvalData.reduce((acc, record) => acc + 1, 0);
  const totalCompletedRecords = completedData.reduce((acc, record) => acc + 1, 0);
  const totalNoConclusionRecords = draftData.reduce((acc, record) => acc + 1, 0);

  const approvalLabel = cardData.map(data => data.user_sgid);
  const userInfo = JSON.parse(localStorage.getItem("allUsersData"));
  const dialogClose = () => {
    //console.log("eenter")
    setEditDialogOpen(false);
  };

  const newDialogRequestClose = () => {
    setNewDialogRequestOpen(false);
  }

  return (
    <>
      <div style={{ justifyContent: "center", padding: 16 }}>
        <DeleteDialog
          open={openDeleteDialog}
          onClose={closeDeleteConfirmation}
          handleDelete={() => {
            deleteMOC(deleteMocId);
            closeDeleteConfirmation(); // Close dialog after deletion
          }}
        />
        {/* <Header pageTitle="Manage MOC" isManageMOCPage={true} /> */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          indicatorColor="primary"
          textColor="primary"
          sx={{
            // maxWidth: "400px",
            "& .MuiTab-textColorPrimary.Mui-selected": {
              color: "#B51849",
              border: "1px solid #B51849",
              borderRadius: "25px"
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#B51849",
              display: {
                xs: "none",
              },
            },
            "& .MuiTabs-flexContainer": {
              display: {
                xs: "block",
              },
              // overflow: {
              //   xs: "auto",
              // },
            },
          }}
        >
          <Tab
            label="All MOC"
            sx={{
              minWidth: "100px",
              width: "auto",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          />
          <Tab
            label="In Progress"
            sx={{
              minWidth: "100px",
              width: "auto",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          />
          <Tab
            label="In Draft"
            sx={{
              minWidth: "100px",
              width: "auto",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          />
          <Tab
            label={approvalLabel.includes(user) ? "Awaiting Approval" : "Pending Approval"}
            sx={{
              minWidth: "100px",
              width: "auto",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          />
          <Tab
            label="Completed"
            sx={{
              minWidth: "100px",
              width: "auto",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          />
          <Tab
            label="Rejected"
            sx={{
              minWidth: "100px",
              width: "auto",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          />
        </Tabs>

        <div style={{ position: "relative", display: "flex", width: "100%", marginTop: "10px", justifyContent: "space-between" }}>
          <img src={SearchIcon} alt="Search Icon" style={{ position: "absolute", top: "50%", left: "10px", transform: "translateY(-50%)", cursor: "pointer", marginLeft: 12 }} />
          <input type="text" placeholder="Search..." style={{ width: "65%", padding: "10px", paddingLeft: "40px", fontSize: "14px", borderRadius: "5px", border: "1px solid #8F908F", marginLeft: 12 }} value={searchText} onChange={handleSearchChange} />
          {/* Filter Tooltip */}
          <Tooltip onClick={openFilter} title="Filter">
            <Box style={{ border: "1px solid #8F908F", borderRadius: 5, width: 60, height: 38, display: "flex", justifyContent: "center", alignItems: "center", cursor: 'pointer' }}>
              <img src={FilterIcon} alt="Filter Icon" />
            </Box>
          </Tooltip>
          <Tooltip title={"New MOC"} arrow>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="add"
              onClick={handleAddClick}
            >
              <img
                src={PlusIcon}
                alt="add icon"
                style={{ width: "28px", height: "24px" }}
              />
            </IconButton>
          </Tooltip>
          {/* <Tooltip onClick={handleAPIClick} title="Filter">
          <div style={{ position: "absolute", top: "50%", right: "0px", transform: "translateY(-50%)", cursor: "pointer", zIndex: 1 }}>
            <div style={{ border: "1px solid #8F908F", borderRadius: 5, width: 40, height: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={FilterIcon} alt="Filter Icon" />
            </div>
          </div>
        </Tooltip> */}
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
            <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSnackbarOpen(false)}>
              Filter Applied
            </MuiAlert>
          </Snackbar>
          <Popover
            id={filterId}
            open={openFilterPopover}
            anchorEl={anchorEl}
            anchorReference="anchorPosition"
            anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              {" "}
              <Grid container style={{ borderBottom: "1px solid #282A2D", display: "flex", alignItems: "center" }}>
                <Grid item xs={11}>
                  <Box p={1} style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                    <Button onClick={handleFilter} style={{ fontWeight: "bold", backgroundColor: "#00448b", color: "#ffffff", borderRadius: "5px", height: "35px" }}>
                      Apply
                    </Button>
                    <Typography style={{ fontWeight: 800, color: "#FAAD14", lineHeight: "16px", marginLeft: "25px", cursor: "pointer", fontSize: "18px" }} onClick={handleResetFilter}>
                      Reset
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <img
                    onClick={closeFilter}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        closeFilter();
                      }
                    }}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    src={CloseIcon}
                    alt="Close"
                    tabIndex="0" // Ensure the element is focusable
                  />
                </Grid>
              </Grid>
              <Card style={{ width: "300px", padding: "10px" }}>
                <Container component="main" maxWidth="xs">
                  {selectedTab === 2 ?
                    <></> :
                    <Accordion
                      expanded={conclusionAccordionExpand}
                      onChange={() => handleAccordionChange("conclusion")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
                          Conclusion
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Checkbox
                            checked={allChecked}
                            // indeterminate={!allChecked && !allUnchecked}
                            indeterminate={
                              !allChecked &&
                              !conclusionCheckbox.every(
                                (checkbox) => !checkbox.checked
                              )
                            }
                            onChange={handleSelectAll}
                            style={{
                              color: allChecked ? "#00448b" : "#D3D3D3",
                            }}
                          />
                          <Typography>Select All</Typography>
                        </div>
                        {conclusionCheckbox.map((item) => (
                          <div key={item.id} style={{ display: "flex", flexDirection: "row" }}>
                            <Checkbox
                              value={item.id}
                              checked={
                                conclusionCheckbox.find(
                                  (checkbox) => checkbox.id === item.id
                                )?.checked || false
                              }
                              onChange={() => {
                                handleCheckbox(item.id);
                                handleFilterOptionSelect(item.name);
                              }}
                              style={{ color: conclusionCheckbox.find((checkbox) => checkbox.id === item.id)?.checked ? "#00448b" : "#D3D3D3" }} />
                            <Typography>{item.name}</Typography>
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  }

                  {/* Date Range Options Accordion */}
                  <Accordion
                    expanded={dateAccordionExpand}
                    onChange={() => handleAccordionChange("date")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
                        MOC Date
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {/* From Date */}
                        <TextField
                          id="fromDate"
                          label="From Date"
                          type="date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "220px" }}
                        />
                        {/* To Date */}
                        <TextField
                          id="toDate"
                          label="To Date"
                          type="date"
                          value={toDate}
                          onChange={handleToDateChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ marginTop: "20px", width: "220px" }}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Container>
              </Card>
            </div>
          </Popover>
        </div>

        <div style={{ marginTop: "10px" }}>
          {initialLoad ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </div>
          ) :
            (
              <div>

                {selectedTab === 0 && (
                  <Grid item md={3} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                    {filterCardData
                      // .slice(0, visibleRecords)
                      .map((moc, index) =>
                        (
                          user === moc.user_sgid || user === moc.approver_sgid ||
                          (moc?.mocForm?.admin_approval_status === true && userName === moc?.mocForm?.quality_name) || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.plant_check === 'Yes' && userName === moc?.mocForm?.plant_name) || (moc?.mocForm?.admin_approval_status === true && userName === moc?.mocForm?.safety_name) || userRole
                        )
                          ? (
                            <React.Fragment key={moc.id}>
                              <Card key={moc.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                  <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', padding: "12px" }}>
                                    {moc?.mocForm?.moc_no ?
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                          MOC NO.
                                        </Typography>
                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "5px" }}>
                                          {moc?.mocForm?.moc_no}
                                        </Typography>
                                      </Box> : <></>
                                    }
                                    {
                                      (user !== moc?.mocForm?.user_id) ||
                                        (moc?.mocForm?.admin_approval_status === true && user === moc?.approver_sgid && ((!moc?.trials_id && !moc?.implement_id) || moc?.trials?.admin_approval_status === false)) ||
                                        (moc?.mocForm?.quality_approval_status === true
                                          && (moc?.mocForm?.created_by === !moc?.mocForm?.quality_name || !moc?.mocForm?.created_by === !moc?.mocForm?.quality_name)
                                          && userName === moc?.mocForm?.quality_name && ((!moc?.trials_id && !moc?.implement_id) || moc?.trials?.admin_approval_status === true)) || (moc?.mocForm?.safety_approval_status === true && (moc?.mocForm?.created_by === !moc?.mocForm?.safety_name || !moc?.mocForm?.created_by === !moc?.mocForm?.safety_name) && userName === moc?.mocForm?.safety_name && ((!moc?.trials_id && !moc?.implement_id) || moc?.trials?.admin_approval_status === true)) || (moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === true && (moc?.mocForm?.created_by === !moc?.mocForm?.plant_name || !moc?.mocForm?.created_by === !moc?.mocForm?.plant_name) && userName === moc?.mocForm?.plant_name && ((!moc?.trials_id && !moc?.implement_id) || moc?.trials?.admin_approval_status === true)) || (moc?.mocForm?.plant_check === "No" && moc?.mocForm?.plant_approval_status === false && (moc?.mocForm?.created_by === !moc?.mocForm?.plant_name || moc?.mocForm?.created_by === !moc?.mocForm?.plant_name) && userName === moc?.mocForm?.plant_name && ((!moc?.trials_id && !moc?.implement_id) || moc?.trials?.admin_approval_status === true)
                                          // || (moc?.mocForm?.conclusion === "Quality Head" && qualityRole && moc?.mocForm?.created_by === moc?.mocForm?.quality_name && moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.quality_approval_status === false)
                                          // || !user === !moc?.mocForm?.user_id && !user === !moc?.mocForm?.admin && userRole
                                        )
                                        ? null :
                                        <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                          <img
                                            src={menu}
                                            alt="menu"
                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                            onClick={(event) => handleMenuClick(event, moc.id)} />
                                        </div>

                                    }
                                    {showMenu && (
                                      <div
                                        ref={menuRef}
                                        style={{
                                          position: "absolute",
                                          top: menuPosition.top,
                                          left: menuPosition.left,
                                          width: 150,
                                          backgroundColor: "white",
                                          boxShadow: "none",
                                          borderRadius: 4,
                                          border: "1px solid #B51849",
                                          zIndex: 10,
                                        }}
                                      >
                                        {!showActionPlan && (
                                          <div
                                            onClick={() => handleMenuItemClick("edit", moc)}
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center"
                                            }}>
                                            <img
                                              src={Edit}
                                              alt="Edit Icon"
                                              style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                            />
                                            {"Edit"}
                                          </div>
                                        )}
                                        {showActionPlan ? (
                                          <div
                                            onClick={() => handleMenuItemClick("actionPlan", moc.id)}
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center"
                                            }}
                                          >
                                            <img
                                              src={Edit}
                                              alt="Action Plan"
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: "5px",
                                                marginBottom: "5px",
                                                cursor: "pointer",
                                              }}
                                            />
                                            Action Plan
                                          </div>) : null
                                        }
                                        {showImplement ? (
                                          <div
                                            onClick={() =>
                                              handleMenuItemClick("implementMOC", moc.id)
                                            }
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center"
                                            }}
                                          >
                                            <img
                                              src={Edit}
                                              alt="Implement MOC"
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: "5px",
                                                marginBottom: "5px",
                                                cursor: "pointer",
                                              }}
                                            />
                                            Implement MOC
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </Box>

                                  <Box sx={{ padding: '0 12px 12px' }}>
                                    <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                      Change Required
                                    </Typography>
                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                      {moc?.mocForm?.requestForm.change_required}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ padding: '0 12px 12px' }}>
                                    {
                                      moc?.mocForm?.conclusion ?
                                        <Chip
                                          label={moc?.mocForm?.conclusion} style={{ backgroundColor: "white", color: "#B51849", fontFamily: "inherit", fontSize: "12px", padding: "4px 8px", border: "1px solid #B51849", fontWeight: 600, width: "fit-content" }} />
                                        : <></>
                                    }
                                  </Box>
                                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                    <Box style={{ display: "flex" }}>
                                      <img
                                        src={TimeLine}
                                        alt="Timeline"
                                        style={{ marginRight: "10px" }}
                                        onClick={(event) => toggleAccordionTimeline(event, moc.moc_id)}
                                      />
                                      <Typography
                                        style={{
                                          color: "#616161",
                                          fontFamily: "inherit",
                                          fontSize: "14px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {moment(moc.mocForm.requestForm.moc_date).format("DD-MMM-YYYY")}
                                      </Typography>
                                    </Box>
                                    <AvatarGroup>
                                      {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.plant_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                              {`${moc?.mocForm?.plant_name.split(" ").pop().charAt(0)}${moc?.mocForm?.plant_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null}

                                      {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.safety_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                              {`${moc?.mocForm?.safety_name.split(" ").pop().charAt(0)}${moc?.mocForm?.safety_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null}

                                      {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.quality_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }} >
                                              {`${moc?.mocForm?.quality_name.split(" ").pop().charAt(0)}${moc?.mocForm?.quality_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null}

                                      {moc?.mocForm?.authority_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.authority_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                              {`${moc?.mocForm?.authority_name.split(" ").pop().charAt(0)}${moc?.mocForm?.authority_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null
                                      }

                                      {moc?.mocForm?.created_by ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.created_by}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                              {`${moc?.mocForm?.created_by.split(" ").pop().charAt(0)}${moc?.mocForm?.created_by.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null
                                      }
                                    </AvatarGroup>
                                  </Box>
                                  {expandedCardId === moc.moc_id && conclusionTimeline.length > 0 && (
                                    <Timeline sx={{
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}>
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                          Conclusion
                                        </Typography>
                                      </Box>
                                      {conclusionTimeline.map((action, index) => (
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            {index !== conclusionTimeline.length - 1 && <TimelineConnector />}
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {action.status + " " + "by" + " " + action.user_approval_name}
                                            </Typography>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </Timeline>
                                  )}
                                  {expandedCardId === moc.moc_id && actionTimeline.length > 0 && (
                                    <Timeline sx={{
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}>
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                          Trials
                                        </Typography>
                                      </Box>
                                      {actionTimeline.map((action, index) => (
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            {index !== actionTimeline.length - 1 && <TimelineConnector />}
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography variant="subtitle2" style={{ color: 'textSecondary', fontSize: '16px' }}>
                                              <span style={{ fontWeight: 'bold', color: '#333333' }}>{action.status + " " + "by" + " " + action.user_approval_name}</span>
                                            </Typography>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </Timeline>
                                  )}
                                  {expandedCardId === moc.moc_id && implementTimeline.length > 0 && (
                                    <Timeline sx={{
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}>
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                          Implement
                                        </Typography>
                                      </Box>
                                      {implementTimeline.map((action, index) => (
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            {index !== implementTimeline.length - 1 && <TimelineConnector />}
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography variant="subtitle2" style={{ color: 'textSecondary', fontSize: '16px' }}>
                                              <span style={{ fontWeight: 'bold', color: '#333333' }}>{action.status + " " + "by" + " " + action.user_approval_name}</span>
                                            </Typography>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </Timeline>
                                  )}
                                </CardContent>
                              </Card>
                            </React.Fragment>
                          ) : null
                      )}
                    {/* {cardData.length === 9 && cardData.length - 1 && (
                     <div>
                       {error && (
                         <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                           {error}
                         </div>
                       )}
                       {loading ? (
                         <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                           <CircularProgress />
                         </div>
                       ) : (
                         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                           {searchText
                             ? renderRecords(filteredSearchRecords)
                             : renderRecords(displayedRecords)}
   
                           {visibleRecords < cardData.length && (
                             <div style={{ flexDirection: "column", alignItems: "center" }}>
                               <div style={{ display: "flex" }}>
                                 <Button
                                   variant="contained"
                                   style={{ width: "200px", backgroundColor: "#00448B", textTransform: "none", fontSize: 24, color: "white", borderRadius: "5px", textAlign: "center" }}
                                   onClick={handleClickSeeMore}>
                                   View More
                                 </Button>
                               </div>
                               <div style={{ textAlign: "center" }}>
                                 <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
                                   {visibleRecords <= totalAllMocRecords ? `${visibleRecords} of ${totalAllMocRecords}` : `${totalAllMocRecords} of ${totalAllMocRecords}`}
                                 </Typography>
                               </div>
                             </div>
                           )}
                         </div>
                       )}
                     </div>
                   )} */}
                    {filterCardData.length === 0 && <p>No records found.</p>}
                  </Grid>
                )}

                {selectedTab === 1 && (
                  <Grid item md={3} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                  <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>PROGRESS</Typography>
                  {filterProgressData
                    // .slice(0, visibleRecords)
                    .map(
                      (moc, index) =>
                        (
                          (// User
                            (moc?.mocForm?.conclusion === "Quality Head" ?
                              (
                                // Section 1 Approval Flow
                                (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_check === "Yes"
                                  && moc?.mocForm?.plant_approval_status === true
                                  && moc?.changeImplementation === null && user === moc.user_sgid) ||
                                (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_check === "No" 
                                  && moc?.changeImplementation === null
                                  && user === moc.user_sgid)
                              ) : (
                                moc?.mocForm?.conclusion === "Safety Head" ?
                                  (
                                    // Section 1 Approval Flow
                                    (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes"
                                      && moc?.changeImplementation === null
                                      && user === moc.user_sgid) ||
                                    (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "No"
                                      && moc?.changeImplementation === null
                                      && user === moc.user_sgid)
                                  ) : (
                                    moc?.mocForm?.conclusion === "Safety & Quality" ?
                                      (
                                        // Section 1 Approval Flow
                                        (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === true && (moc?.trials === null || moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.trials?.plant_approval_status === true)
                                          && moc?.changeImplementation === null
                                          && user === moc.user_sgid) ||
                                        (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === true && (moc?.mocForm?.plant_check === "No" && (moc?.trials === null || moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === true)
                                          && moc?.changeImplementation === null) && user === moc.user_sgid)
                                      )
                                      :
                                      (
                                        moc?.mocForm?.conclusion === "Non Critical" &&
                                        (
                                          moc?.mocForm?.admin_approval_status === true
                                          && moc?.changeImplementation === null
                                        ) &&
                                        user === moc.user_sgid
                                      )
                                  )
                              )
                            )
                          )
                          // Admin
                          // Quality Head
                          || (moc?.mocForm?.conclusion === "Quality Head" &&
                            // Section 1 Approval Flow
                            (moc?.mocForm?.admin_approval_status === true
                              && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && (moc?.changeImplementation.plant_check === null || (moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false))) && user === moc.approver_sgid)
                            ||
                            (moc?.mocForm?.admin_approval_status === true 
                              && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && (moc?.changeImplementation.plant_check === null || (moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false))) && user === moc.approver_sgid)
                          )
                          // Safety Head
                          || (moc?.mocForm?.conclusion === "Safety Head" &&
                            // Section 1 Approval Flow
                            (moc?.mocForm?.admin_approval_status === true
                            && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && (moc?.changeImplementation.plant_check === null || (moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false))) && user === moc.approver_sgid)
                          ||
                          (moc?.mocForm?.admin_approval_status === true 
                            && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && (moc?.changeImplementation.plant_check === null || (moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false))) && user === moc.approver_sgid)
                          )
                          // Safety & Quality
                          || (moc?.mocForm?.conclusion === "Safety & Quality" &&
                            // (moc?.mocForm?.admin_approval_status === true && moc?.trials_id && moc?.trials.admin_approval_status && user === moc.approver_sgid)
                            // ||
                            (moc?.mocForm?.admin_approval_status === true && moc?.trials_id && moc?.trials.admin_approval_status && moc?.implement_id && !moc?.changeImplementation.admin_approval_status && user === moc.approver_sgid)
                          )
                          // Non Critical
                          ||
                          (
                            moc?.mocForm?.conclusion === "Non Critical" &&
                            (
                              moc?.mocForm?.submission_status === "approved"
                              && moc?.changeImplementation === null
                            ) &&
                            user === moc.approver_sgid
                          )
                          // Quality Head
                          || (moc?.mocForm?.conclusion === "Quality Head" &&
                            // Section 1 Approval Flow
              
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status===true && moc?.mocForm?.plant_check === "Yes"
                              && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.quality_approval_status===true && moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false) && user === moc?.mocForm?.quality_name)
                            ||
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status===true && moc?.mocForm?.plant_check === "No"
                              && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.quality_approval_status===true && moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false) && user === moc?.mocForm?.quality_name)
                            // // Section 2 Approval Flow
                            // || (moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true
                            //   && userName === moc?.mocForm?.quality_name)
                          )
                          || (moc?.mocForm?.conclusion === "Safety Head" &&
                            // Section 1 Approval Flow
                            // (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && (moc?.trials === null || moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.changeImplementation === null || moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "No")
                            //   && userName === moc?.mocForm?.safety_name)
                            // ||
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status===true && moc?.mocForm?.plant_check === "Yes" 
                              && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.safety_approval_status===true && moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false) && user === moc?.mocForm?.safety_name)
                            ||
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status===true && moc?.mocForm?.plant_check === "No" && 
                            (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.safety_approval_status===true && moc?.changeImplementation.plant_check === "Yes" && moc?.changeImplementation.plant_approval_status===false) && user === moc?.mocForm?.safety_name)
                          )
                          // Safety & Quality
                          || (
                            moc?.mocForm?.conclusion === "Safety & Quality" &&
                            (
                              // Section 1 Approval Flow||
                              (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.trials_id === null && moc?.changeImplementation === null && userName === moc?.mocForm?.quality_name) ||
                              (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && userName === moc?.mocForm?.quality_name) ||
                              (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.trials_id === null && moc?.changeImplementation === null && userName === moc?.mocForm?.safety_name) ||
                              (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.trials?.admin_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && userName === moc?.mocForm?.safety_name) ||
                              (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_approval_status === true && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.trials?.plant_approval_status === true && moc?.changeImplementation === null && userName === moc?.mocForm?.safety_name)
                            )
                          )
                          // Plant Head
                          // Section 1Approval Flow
                          || (moc?.mocForm?.conclusion === "Quality Head" && (moc?.mocForm?.plant_check === "Yes"||moc?.changeImplementation?.plant_check === "Yes") &&
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && (moc?.mocForm?.plant_approval_status === true) && ((moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.quality_approval_status === false))
                              && userName === moc?.mocForm?.plant_name))
                          || (moc?.mocForm?.conclusion === "Safety Head" && (moc?.mocForm?.plant_check === "Yes"||moc?.changeImplementation?.plant_check === "Yes") &&
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && (moc?.mocForm?.plant_approval_status === true) && ((moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.safety_approval_status === false))
                              && userName === moc?.mocForm?.plant_name))
                          || (moc?.mocForm?.conclusion === "Safety & Quality" && moc?.mocForm?.plant_check === "Yes" &&
                            (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_approval_status === true &&
                              (moc?.trials === null || moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.trials?.plant_approval_status === true) &&
                              (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.quality_approval_status === true && moc?.changeImplementation.safety_approval_status === false && moc?.changeImplementation.plant_approval_status === true)
                              && userName === moc?.mocForm?.plant_name)
                            // ||
                            // (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true
                            //   && moc?.mocForm?.plant_approval_status === true && moc?.trials?.trials_status === "approved"
                            //   && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.plant_approval_status === true
                            //   && moc?.trials?.plant_check === "Yes" && (moc?.changeImplementation === null || moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_approval_status === false)
                            //   && userName === moc?.mocForm?.plant_name)

                          )
                          // Safety Head
                          // || (moc?.mocForm?.conclusion === "Safety Head" &&
                          //   // Section 1 Approval Flow
                          //   (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && (moc?.trials === null || moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.safety_approval_status === true && (moc?.changeImplementation === null || moc?.changeImplementation.admin_approval_status === true && moc?.changeImplementation.safety_approval_status === true && moc?.changeImplementation.plant_approval_status === false))
                          //     && userName === moc?.mocForm?.safety_name)
                          // )
                        ) ? (
                          <React.Fragment key={moc.id}>
                            <Card key={moc.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                              <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', padding: "12px" }}>
                                  {moc?.mocForm?.moc_no ?
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                        MOC NO.
                                      </Typography>
                                      <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "5px" }}>
                                        {moc?.mocForm?.moc_no}
                                      </Typography>
                                    </Box> : <></>
                                  }
                                  {
                                    (moc?.mocForm?.admin_approval_status === true && user === moc?.approver_sgid && ((!moc?.trials_id && !moc?.implement_id || moc?.trials_id) || moc?.trials?.admin_approval_status === true))
                                      || (moc?.mocForm?.quality_approval_status === true && (moc?.mocForm?.created_by === !moc?.mocForm?.quality_name || !moc?.mocForm?.created_by === !moc?.mocForm?.quality_name) && userName === moc?.mocForm?.quality_name && ((!moc?.trials_id && !moc?.implement_id || moc?.trials_id) || moc?.trials?.admin_approval_status === true))
                                      || (moc?.mocForm?.safety_approval_status === true && (moc?.mocForm?.created_by === !moc?.mocForm?.safety_name || !moc?.mocForm?.created_by === !moc?.mocForm?.safety_name) && userName === moc?.mocForm?.safety_name && ((!moc?.trials_id && !moc?.implement_id || moc?.trials_id) || moc?.trials?.admin_approval_status === true))
                                      || (moc?.mocForm?.plant_check === "Yes" && (moc?.mocForm?.created_by === !moc?.mocForm?.plant_name || !moc?.mocForm?.created_by === !moc?.mocForm?.plant_name) && moc?.mocForm?.plant_approval_status === true && userName === moc?.mocForm?.plant_name && ((!moc?.trials_id && !moc?.implement_id || moc?.trials_id) || moc?.trials?.admin_approval_status === true)) || (moc?.mocForm?.plant_check === "No" && (moc?.mocForm?.created_by === !moc?.mocForm?.plant_name || !moc?.mocForm?.created_by === !moc?.mocForm?.plant_name) && moc?.mocForm?.plant_approval_status === false && userName === moc?.mocForm?.plant_name && ((!moc?.trials_id && !moc?.implement_id || moc?.trials_id) || moc?.trials?.admin_approval_status === true))
                                      ? null :

                                      <Button
                                        sx={{
                                          padding: '0',
                                          minWidth: '30px'
                                        }}
                                        id="basic-button"
                                        onClick={(event) => handleMenuClick(event, moc.id)}
                                      >
                                        <MoreVertIcon sx={{ color: '#000' }} />
                                      </Button>
                                  }
                                  {showMenu && openedMenuId === moc.id && (
                                    <div
                                      ref={menuRef}
                                      style={{
                                        position: "absolute",
                                        top: 30,
                                        right: 20,
                                        width: 150,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        borderRadius: 4,
                                        border: "1px solid #B51849",
                                        zIndex: 10,
                                      }}
                                    >
                                      {!showActionPlan ? (
                                        <div
                                          onClick={() => handleMenuItemClick("edit", moc)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}
                                        >
                                          <>
                                            <img
                                              src={Edit}
                                              alt="Edit Icon"
                                              style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                            />
                                            <Typography>Edit</Typography>
                                          </>
                                        </div>
                                      ) : null}

                                      {showActionPlan && (
                                        <div
                                          onClick={() => handleMenuItemClick("actionPlan", moc.id)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                          <img
                                            src={Edit}
                                            alt="Action Plan"
                                            style={{
                                              verticalAlign: "middle",
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              cursor: "pointer",
                                            }}
                                          />Action Plan
                                        </div>)
                                      }
                                      {showImplement && (
                                        <div
                                          onClick={() => handleMenuItemClick("implementMOC", moc.id)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                          <img
                                            src={Edit}
                                            alt="Implement MOC"
                                            style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }} />
                                          Implement MOC
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Box>
                                <Box sx={{ padding: '0 12px 12px' }}>
                                  <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                    Change Required
                                  </Typography>
                                  <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                    {moc?.mocForm?.requestForm.change_required}
                                  </Typography>
                                </Box>
                                <Box sx={{ padding: '0 12px 12px' }}>
                                <Chip
  label={
    moc?.mocForm?.conclusion === "Quality Head"
      ? "Critical to Quality"
      : moc?.mocForm?.conclusion === "Safety Head"
      ? "Critical to Safety"
      : moc?.mocForm?.conclusion // Default to the original conclusion text if it doesn’t match
  }
  style={{
    backgroundColor: "white",
    color: "#B51849",
    fontFamily: "inherit",
    fontSize: "10px",
    padding: "2px 0",
    border: "1px solid #B51849",
    fontWeight: 600,
    width: "fit-content",
    height: '24px'
  }}
/>

                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                  <Box style={{ display: "flex" }}>
                                    <img
                                      src={TimeLine}
                                      alt="Timeline"
                                      style={{ marginRight: "10px" }}
                                      onClick={(event) => toggleAccordionTimeline(event, moc.moc_id)}
                                    />
                                    <Typography
                                      style={{
                                        color: "#616161",
                                        fontFamily: "inherit",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {moment(moc.mocForm.requestForm.moc_date).format("DD-MMM-YYYY")}
                                    </Typography>
                                  </Box>
                                  <AvatarGroup>
                                    {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.plant_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                            {`${moc?.mocForm?.plant_name.split(" ").pop().charAt(0)}${moc?.mocForm?.plant_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null}

                                    {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.safety_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                            {`${moc?.mocForm?.safety_name.split(" ").pop().charAt(0)}${moc?.mocForm?.safety_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null}

                                    {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.quality_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }} >
                                            {`${moc?.mocForm?.quality_name.split(" ").pop().charAt(0)}${moc?.mocForm?.quality_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null}

                                    {moc?.mocForm?.authority_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.authority_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                            {`${moc?.mocForm?.authority_name.split(" ").pop().charAt(0)}${moc?.mocForm?.authority_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null
                                    }

                                    {moc?.mocForm?.created_by ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.created_by}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px" }}>
                                            {`${moc?.mocForm?.created_by.split(" ").pop().charAt(0)}${moc?.mocForm?.created_by.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null
                                    }
                                  </AvatarGroup>
                                </Box>
                                {expandedCardId === moc.moc_id && conclusionTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Conclusion
                                      </Typography>
                                    </Box>
                                    {conclusionTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== conclusionTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )}
                                {/* {expandedCardId === moc.moc_id && actionTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Trials
                                      </Typography>
                                    </Box>
                                    {actionTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== actionTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )} */}
                                {expandedCardId === moc.moc_id && implementTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Implement
                                      </Typography>
                                    </Box>
                                    {implementTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== implementTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )}
                              </CardContent>
                            </Card>
                          </React.Fragment>
                        ) : null
                    )}
                  {filterProgressData.length === 9 && filterProgressData.length - 1 && (
                    <Box>
                      {error && (
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {error}
                        </Box>
                      )}
                      {/* {loading ? (
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 30 }}>
                          {searchText
                            ? renderRecords(filteredSearchRecords)
                            : renderRecords(displayedRecords)}
                          {visibleRecords < totalProgressRecords && (
                            <Box style={{ marginTop: "20px", display: "flex", flex: 1 }}>
                              <Button
                                variant="contained"
                                style={{ width: "200px", backgroundColor: "#00448B", textTransform: "none", fontSize: 24, color: "white", borderRadius: "5px", textAlign: "center" }}
                                onClick={handleClickSeeMore}
                              >
                                View More
                              </Button>
                            </Box>
                          )}
                          <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
                            {visibleRecords <= totalProgressRecords ? `${visibleRecords} of ${totalProgressRecords}` : `${totalProgressRecords} of ${totalProgressRecords}`}
                          </Typography>
                        </Box>
                      )} */}
                    </Box>
                  )}
                  {filterProgressData.length === 0 && <p>No records found.</p>}
                </Grid>
                )}

                {selectedTab === 2 && (
                  <Grid item md={3} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                    {filterDraftData
                      // .slice(0, visibleRecords)
                      .map((moc, index) =>
                        (user === moc.user_sgid) ? (
                          <React.Fragment key={moc.id}>
                            <Card key={moc.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                              <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>

                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', padding: "12px" }}>
                                  <Box sx={{ width: '100%' }}>
                                    <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                      Change Required
                                    </Typography>
                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' } }}>
                                      {moc?.mocForm?.requestForm.change_required}
                                    </Typography>
                                  </Box>
                                  <Box >
                                    <Button
                                      sx={{
                                        padding: '0',
                                        minWidth: '30px'
                                      }}
                                      id="basic-button"
                                      onClick={(event) => handleMenuClick(event, moc.id)}
                                    >
                                      <MoreVertIcon sx={{ color: '#000' }} />
                                    </Button>
                                  </Box>

                                  {showMenu && openedMenuId === moc.id && (
                                    <div
                                      ref={menuRef}
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 20,
                                        width: 150,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        borderRadius: 4,
                                        border: "1px solid #B51849",
                                        zIndex: 10,
                                      }}
                                    >
                                      <div
                                        onClick={() => handleMenuItemClick("edit", moc)}
                                        style={{
                                          padding: "10px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <img
                                          src={Edit}
                                          alt="Edit Icon"
                                          style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }} />
                                        {"Edit"}
                                      </div>
                                      <div
                                        onClick={() =>
                                          handleMenuItemClick(
                                            "delete",
                                            mocsData,
                                            index,
                                            moc.id
                                          )
                                        }
                                        style={{
                                          padding: "10px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center"
                                        }}>
                                        <img
                                          src={Delete}
                                          alt="Action Plan"
                                          style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }} />
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", padding: "4px 12px", borderTop: '1px solid #f2f2f2' }}>
                                  <Box style={{ display: "flex" }}>
                                    <img
                                      src={TimeLine}
                                      alt="Timeline"
                                      style={{ marginRight: "10px" }}
                                      onClick={(event) => toggleAccordionTimeline(event, moc.moc_id)}
                                    />
                                    <Typography
                                      style={{
                                        color: "#616161",
                                        fontFamily: "inherit",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {moment(moc.mocForm.requestForm.moc_date).format("DD-MMM-YYYY")}
                                    </Typography>
                                  </Box>

                                  <AvatarGroup>
                                    {moc?.mocForm?.created_by &&
                                      <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginLeft: "50px" }}>
                                        {`${moc?.mocForm?.created_by.split(" ").pop().charAt(0)}${moc?.mocForm?.created_by.charAt(0)}`}
                                      </Avatar>
                                    }
                                  </AvatarGroup>

                                </Box>
                                {expandedCardId === moc.moc_id && conclusionTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Conclusion
                                      </Typography>
                                    </Box>
                                    {conclusionTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== conclusionTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )}
                              </CardContent>
                            </Card>
                          </React.Fragment>
                        ) : null
                      )}
                    {filterDraftData.length === 9 && filterDraftData.length - 1 && (
                      <Box>
                        {error && (
                          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {error}
                          </Box>
                        )}
                        {loading ? (
                          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 30 }}>
                            {searchText
                              ? renderRecords(filteredSearchRecords)
                              : renderRecords(displayedRecords)}
                            {visibleRecords < totalNoConclusionRecords && (
                              <Box style={{ marginTop: "20px", display: "flex", flex: 1 }}>
                                <Button
                                  variant="contained"
                                  style={{ width: "200px", backgroundColor: "#00448B", textTransform: "none", fontSize: 24, color: "white", borderRadius: "5px", textAlign: "center" }}
                                  onClick={handleClickSeeMore}>
                                  View More
                                </Button>
                              </Box>
                            )}
                            <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
                              {visibleRecords <= totalNoConclusionRecords ? `${visibleRecords} of ${totalNoConclusionRecords}` : `${totalNoConclusionRecords} of ${totalNoConclusionRecords}`}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    {filterDraftData.length === 0 && <p>No records found.</p>}
                  </Grid>
                )}

                {selectedTab === 3 && (
                  <Grid item md={3} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                  <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>{approvalLabel.includes(user) ? "AWAITING APPROVAL" : "PENDING APPROVAL"}</Typography>
                  {filterApprovalData
                    // .slice(0, visibleRecords)
                    .map(
                      (moc, index) =>
                        ( // User
                       
                          (moc?.mocForm?.conclusion === "Quality Head" ?
                            (
                              // Section 1 Approval Flow
                              (moc?.mocForm?.admin_approval_status === false) && user === moc.user_sgid
                              || (moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === false) && user === moc.user_sgid
                              || (moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.plant_check === "No" && moc?.mocForm?.plant_approval_status === false) && user === moc.user_sgid
                              || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.plant_approval_status === false) && user === moc.user_sgid
                              || (moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === false) && user === moc.user_sgid
                              || (moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_check === "Yes") && user === moc.user_sgid
                              || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.submission_status === "rejected") && user === moc.user_sgid
                              // Section 2 Approval Flow
                              // || (moc?.trials?.trials_status === "pending" && user === moc.user_sgid)
                              //  || (moc?.mocForm?.submission_status === "approved" && moc?.trials?.trials_status === "pending" && moc?.trials?.admin_approval_status === false) && user === moc.user_sgid
                              // Section 3 Approval Flow
                              || (moc?.changeImplementation?.implement_status === "pending" && (moc?.changeImplementation?.admin_approval_status === false) && user === moc.user_sgid)
                              || (moc?.changeImplementation?.implement_status === "resubmitted" && (moc?.changeImplementation?.admin_approval_status === false) && user === moc.user_sgid)
                              || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") &&
                                ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "No") && user === moc.user_sgid)
                              || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                              || (moc?.changeImplementation?.implement_status === "approved"  && moc?.changeImplementation?.product_compliance_check === "Yes" &&  moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                              || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                              || ((moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                              || ((moc?.changeImplementation?.implement_status === "approved") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                              || ((moc?.changeImplementation?.implement_status === "approved") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                            )
                            :
                            moc?.mocForm?.conclusion === "Safety Head" ?
                              (
                                // Section 1 Approval Flow
                                // (moc?.mocForm?.admin_approval_status === false && user === moc.user_sgid)
                                // ||
                                (moc?.mocForm?.submission_status === "pending" || moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_approval_status === false) && user === moc.user_sgid
                                || (moc?.mocForm?.submission_status === "pending" || moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false) && user === moc.user_sgid
                                ||
                                (moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_check === "No" && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                // || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false && user === moc.user_sgid)
                                || (moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes" && user === moc.user_sgid)
                                // Section 2 Approval Flow
                                // Section 3 Approval Flow
                                || (moc?.changeImplementation?.implement_status === "pending" && (moc?.changeImplementation?.admin_approval_status === false) && user === moc.user_sgid)
                                || (moc?.changeImplementation?.implement_status === "resubmitted" && (moc?.changeImplementation?.admin_approval_status === false) && user === moc.user_sgid)
                                || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") &&
                                  ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "No") && user === moc.user_sgid)
                                || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.plant_checkk === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.changeImplementation?.plant_check === "No" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                                ||
                                ((moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && user === moc.user_sgid
                              ) :
                              moc?.mocForm?.conclusion === "Safety & Quality" ?
                                ( // Section 1 Approval Flow
                                  (moc?.mocForm?.admin_approval_status === false && user === moc.user_sgid)
                                  || (moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                  || (moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_check === "No" && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                  || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                  || (moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                  || (moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes" && user === moc.user_sgid)
                                  || (moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "No" && user === moc.user_sgid)
                                  || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.submission_status === "rejected" && user === moc.user_sgid)
                                  || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_approval_status === false && user === moc.user_sgid)
                                )
                                // Section 2 Approval Flow
                                || (moc?.trials?.trials_status === "pending" && (moc?.trials?.admin_approval_status === false && user === moc.user_sgid))
                                || (moc?.trials?.trials_status === "resubmitted" && (moc?.trials?.admin_approval_status === false && user === moc.user_sgid))
                                || (moc?.trials?.trials_status === "resubmitted" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === false && moc?.trials?.safety_approval_status === false && moc?.trials?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === false && moc?.trials?.safety_approval_status === false && moc?.mocForm?.plant_check === "No" && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === false && moc?.trials?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.trials?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.trials?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.trials?.plant_approval_status === true && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "resubmitted" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === false && moc?.trials?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "resubmitted" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.trials?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.trials?.trials_status === "resubmitted" && moc?.trials?.admin_approval_status === true && moc?.trials?.quality_approval_status === true && moc?.trials?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.trials?.plant_approval_status === true && user === moc.user_sgid)
                                // Section 3 Approval Flow
                                || (moc?.changeImplementation?.implement_status === "pending" && (moc?.changeImplementation?.admin_approval_status === false && user === moc.user_sgid))
                                || (moc?.changeImplementation?.implement_status === "resubmitted" && (moc?.changeImplementation?.admin_approval_status === false && user === moc.user_sgid))
                                || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") &&
                                  ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "No" && user === moc.user_sgid))
                                || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false && user === moc.user_sgid)
                                || ((moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "No" && moc?.changeImplementation?.plant_approval_status === false && user === moc.user_sgid)
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && user === moc.user_sgid)
                                || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && user === moc.user_sgid)
                                : ""
                          )
                          // Non Critical
                          || (moc?.mocForm?.conclusion === "Non Critical" && user === moc.user_sgid)

                          // Admin
                          // Quality Head
                          || (
                            // Section 1 Approval Flow
                            (moc?.mocForm?.conclusion === "Quality Head" && moc?.mocForm?.submission_status === "pending" || moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === false && moc?.mocForm?.safety_approval_status === false && moc?.mocForm?.plant_approval_status === false) && user === moc.approver_sgid
                            || (moc?.mocForm?.conclusion === "Quality Head" && moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.admin_approval_status === false && moc?.changeImplementation?.quality_approval_status === false) && user === moc.approver_sgid
                            || (moc?.mocForm?.conclusion === "Safety Head" && moc?.mocForm?.submission_status === "pending" && moc?.mocForm?.admin_approval_status === false) && user === moc.approver_sgid
                            || (moc?.mocForm?.conclusion === "Safety Head" && moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === false) && user === moc.approver_sgid
                            || (moc?.mocForm?.conclusion === "Safety Head" && moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.admin_approval_status === false && moc?.changeImplementation?.safety_approval_status === false) && user === moc.approver_sgid
                            || moc?.mocForm?.conclusion === "Safety & Quality" && moc?.mocForm?.admin_approval_status === false
                            && user === moc.approver_sgid
                          )
                          // Non Critical
                          || (moc?.mocForm?.conclusion === "Non Critical" && user === moc.approver_sgid)

                          // Quality Head
                          // Section 1 Approval Flow
                          || (moc?.mocForm?.submission_status === "pending" && moc?.mocForm?.admin_approval_status === true) && userName === moc?.mocForm?.quality_name
                          || (moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false) && userName === moc?.mocForm?.quality_name
                          || (moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false) && userName === moc?.mocForm?.quality_name
                          // Section 2 Approval Flow
                          // Section 3 Approval Flow
                          || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "No" && moc?.changeImplementation?.plant_approval_status === false && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.mocForm?.plant_check === "No" && moc?.changeImplementation?.plant_approval_status === false && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === true && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === true && userName === moc?.mocForm?.quality_name)
                          || (moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.mocForm?.plant_check === "No" && userName === moc?.mocForm?.quality_name)

                          // Safety Head
                          // Section 1 Approval Flow
                          || (moc?.mocForm?.submission_status === "pending" && moc?.mocForm?.admin_approval_status === true) && userName === moc?.mocForm?.safety_name
                          || (moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false) && userName === moc?.mocForm?.safety_name
                          || (moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false) && userName === moc?.mocForm?.safety_name
                          // Section 2 Approval Flow
                          // Section 3 Approval Flow
                          || ((moc?.changeImplementation?.implement_status === "pending" || moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "No" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.mocForm?.plant_check === "No" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === true) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === true) && userName === moc?.mocForm?.safety_name
                          || (moc?.changeImplementation?.implement_status === "resubmitted" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.mocForm?.plant_check === "No") && userName === moc?.mocForm?.safety_name

                          // Plant Head
                          // Section 1 Approval Flow
                          // || userName === moc?.mocForm?.plant_name
                          || (moc?.mocForm?.conclusion === "Quality Head" && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_approval_status === false && moc?.mocForm?.plant_check === 'Yes') && userName === moc?.mocForm?.plant_name
                          || (moc?.mocForm?.conclusion === "Safety Head" && moc?.mocForm?.submission_status === "pending" || moc?.mocForm?.submission_status === "resubmitted" && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_approval_status === false && moc?.mocForm?.plant_check === 'Yes') && userName === moc?.mocForm?.plant_name
                          || (moc?.mocForm?.conclusion === "Safety Head" && moc?.mocForm?.submission_status === "approved" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_check === 'Yes' && moc?.mocForm?.plant_approval_status === false) && userName === moc?.mocForm?.plant_name
                          || (moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_approval_status === false && moc?.mocForm?.plant_check === 'Yes') && userName === moc?.mocForm?.plant_name
                          // Section 2 Approval Flow
                          // || (moc?.trials?.trials_status === "approved" && moc?.trials?.admin_approval_status === true && moc?.trials?.safety_approval_status === true && moc?.trials?.plant_approval_status === false && userName === moc?.mocForm?.plant_name)
                          // Section 3 Approval Flow
                          || ((moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.plant_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.plant_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.plant_name
                          || (moc?.changeImplementation?.implement_status === "approved" && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.plant_check === "Yes" && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.plant_name
                          || ((moc?.changeImplementation?.implement_status === "resubmitted") && moc?.changeImplementation?.product_compliance_check === "Yes" && moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_approval_status === false) && userName === moc?.mocForm?.plant_name
                        ) ? (
                          <React.Fragment key={moc.id}>
                            <Card key={moc.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                              <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', padding: "12px" }}>
                                  {moc?.mocForm?.moc_no ?
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                        MOC NO.
                                      </Typography>
                                      <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "5px" }}>
                                        {moc?.mocForm?.moc_no}
                                      </Typography>
                                    </Box> : <></>
                                  }
                                  <Button
                                    sx={{
                                      padding: '0',
                                      minWidth: '30px'
                                    }}
                                    id="basic-button"
                                    onClick={(event) => handleMenuClick(event, moc.id)}
                                  >
                                    <MoreVertIcon sx={{ color: '#000' }} />
                                  </Button>

                                  {showMenu && filterApprovalData && openedMenuId === moc.id && (
                                    <div
                                      ref={menuRef}
                                      style={{
                                        position: "absolute",
                                        top: 30,
                                        right: 20,
                                        width: 150,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        borderRadius: 4,
                                        border: "1px solid #B51849",
                                        zIndex: 10,
                                      }}
                                    >
                                      {!showActionPlan ? (
                                        <div
                                          onClick={() => handleMenuItemClick("edit", moc)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}
                                        >
                                          <>
                                            <img
                                              src={Edit}
                                              alt="Edit Icon"
                                              style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                            />
                                            <Typography>Edit</Typography>
                                          </>
                                        </div>
                                      ) : null}

                                      {showActionPlan && (
                                        <div
                                          onClick={() => handleMenuItemClick("actionPlan", moc.id)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                          <img
                                            src={Edit}
                                            alt="Action Plan"
                                            style={{
                                              verticalAlign: "middle",
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              cursor: "pointer",
                                            }}
                                          />Action Plan
                                        </div>)
                                      }
                                      {showImplement && (
                                        <div
                                          onClick={() => handleMenuItemClick("implementMOC", moc.id)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                          <img
                                            src={Edit}
                                            alt="Implement MOC"
                                            style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }} />
                                          Implement MOC
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Box>
                                <Box sx={{ padding: '0 12px 12px' }}>
                                  <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                    Change Required
                                  </Typography>
                                  <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                    {moc?.mocForm?.requestForm.change_required}
                                  </Typography>
                                </Box>
                                <Box sx={{ padding: '0 12px 12px' }}>
                                <Chip
  label={
    moc?.mocForm?.conclusion === "Quality Head"
      ? "Critical to Quality"
      : moc?.mocForm?.conclusion === "Safety Head"
      ? "Critical to Safety"
      : moc?.mocForm?.conclusion // Default to the original conclusion text if it doesn’t match
  }
  style={{
    backgroundColor: "white",
    color: "#B51849",
    fontFamily: "inherit",
    fontSize: "10px",
    padding: "2px 0",
    border: "1px solid #B51849",
    fontWeight: 600,
    width: "fit-content",
    height: '24px'
  }}
/>

                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                  <Box style={{ display: "flex" }}>
                                    <img
                                      src={TimeLine}
                                      alt="Timeline"
                                      style={{ marginRight: "10px" }}
                                      onClick={(event) => toggleAccordionTimeline(event, moc.moc_id)}
                                    />
                                    <Typography
                                      style={{
                                        color: "#616161",
                                        fontFamily: "inherit",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {moment(moc.mocForm.requestForm.moc_date).format("DD-MMM-YYYY")}
                                    </Typography>
                                  </Box>
                                  <AvatarGroup>
                                    {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.plant_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                            {`${moc?.mocForm?.plant_name.split(" ").pop().charAt(0)}${moc?.mocForm?.plant_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null}

                                    {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.safety_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                            {`${moc?.mocForm?.safety_name.split(" ").pop().charAt(0)}${moc?.mocForm?.safety_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null}

                                    {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.quality_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }} >
                                            {`${moc?.mocForm?.quality_name.split(" ").pop().charAt(0)}${moc?.mocForm?.quality_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null}

                                    {moc?.mocForm?.authority_name ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.authority_name}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                            {`${moc?.mocForm?.authority_name.split(" ").pop().charAt(0)}${moc?.mocForm?.authority_name.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null
                                    }

                                    {moc?.mocForm?.created_by ?
                                      <Box style={{ position: "relative" }}>
                                        <Tooltip title={moc?.mocForm?.created_by}>
                                          <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                            {`${moc?.mocForm?.created_by.split(" ").pop().charAt(0)}${moc?.mocForm?.created_by.charAt(0)}`}
                                          </Avatar>
                                        </Tooltip>
                                      </Box> : null
                                    }
                                  </AvatarGroup>
                                </Box>
                                {expandedCardId === moc.moc_id && conclusionTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Conclusion
                                      </Typography>
                                    </Box>
                                    {conclusionTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== conclusionTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )}
                                {/* {expandedCardId === moc.moc_id && actionTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Trials
                                      </Typography>
                                    </Box>
                                    {actionTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== actionTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )} */}
                                {expandedCardId === moc.moc_id && implementTimeline.length > 0 && (
                                  <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0,
                                    },
                                  }}>
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                        Implement
                                      </Typography>
                                    </Box>
                                    {implementTimeline.map((action, index) => (
                                      <TimelineItem key={index}>
                                        <TimelineSeparator>
                                          <TimelineDot color="primary" />
                                          {index !== implementTimeline.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {action.status + " " + "by" + " " + action.user_approval_name}
                                          </Typography>
                                          <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                            {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                )}
                              </CardContent>
                            </Card>
                          </React.Fragment>
                        ) : null
                    )}
                  {approvalData.length === 9 && approvalData.length - 1 && (
                    <Box>
                      {error && (
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {error}
                        </Box>
                      )}
                      {/* {loading ? (
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 30 }} >
                          {searchText
                            ? renderRecords(filteredSearchRecords)
                            : renderRecords(displayedRecords)}
                          {visibleRecords < totalApprovalRecords && (
                            <Box style={{ marginTop: "20px", display: "flex", flex: 1 }}>
                              <Button
                                variant="contained"
                                style={{ width: "200px", backgroundColor: "#00448B", textTransform: "none", fontSize: 24, color: "white", borderRadius: "5px", textAlign: "center" }}
                                onClick={handleClickSeeMore}>
                                View More
                              </Button>
                            </Box>
                          )}
                          <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
                            {visibleRecords <= totalApprovalRecords ? `${visibleRecords} of ${totalApprovalRecords}` : `${totalApprovalRecords} of ${totalApprovalRecords}`}
                          </Typography>
                        </Box>
                      )} */}
                    </Box>
                  )}
                  {approvalData.length === 0 && <p>No records found.</p>}
                </Grid>
                )}

                {selectedTab === 4 && (
                   <Grid item md={3} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                   <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>APPROVED</Typography>
                   {filterCompleteData.map((moc, index) => (
                     // User
                     user === moc.user_sgid ||
                       // Admin
                       user === moc.approver_sgid
                       ||
                       userName === moc?.mocForm?.quality_name
                       ||
                       userName === moc?.mocForm?.safety_name
                       ||
                       userName === moc?.mocForm?.plant_name
                       ? (
                         <React.Fragment key={moc.id}>
                           <Card key={moc.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                             <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                               <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', padding: "12px" }}>
                                 {moc?.mocForm?.moc_no ?
                                   <Box>
                                     <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                       MOC NO.
                                     </Typography>
                                     <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "5px" }}>
                                       {moc?.mocForm?.moc_no}
                                     </Typography>
                                   </Box> : <></>
                                 }
                                 <Button
                                   sx={{
                                     padding: '0',
                                     minWidth: '30px'
                                   }}
                                   id="basic-button"
                                   onClick={(event) => handlePdfMenuClick(event, moc.id)}
                                 >
                                   <MoreVertIcon sx={{ color: '#000' }} />
                                 </Button>
                                 {showPdfMenu && openedMenuId === moc.id && (
 <Box
   ref={menuRef}
   style={{
     position: "absolute",
     top: 30,
     right: 20,
     width: 180,
     backgroundColor: "white",
     boxShadow: "none",
     borderRadius: 4,
     border: "1px solid #B51849",
     zIndex: 10,
     padding: "10px",
     cursor: "pointer",
     display: "flex",
     flexDirection: "column",  // Arrange items vertically
     alignItems: "flex-start"  // Align items to the left
   }}
 >
   <Box
     style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "10px" }}
     onClick={handlePDF}
   >
     <DownloadIcon />
     <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
       Download PDF
     </Typography>
   </Box>

   <Box
     style={{ display: "flex", alignItems: "center", gap: "8px" }}
     onClick={handleDownloadDocuments}
   >
     <DownloadIcon />
     <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
       Download Document
     </Typography>
   </Box>
 </Box>
)}

                                 {
                                   previewForm ?
                                     <>
                                       <Box id="cust-print" style={{ display: "none", marginTop: 20 }}>
                                         <Box ref={printRef} style={{
                                           margin: "10px",
                                           width: "1150px"
                                         }}>
                                           <style>{portraitOrientation()}</style>
                                           <PreviewPdf data={mocPdfData} />

                                         </Box>
                                       </Box>
                                     </>
                                     :
                                     <></>
                                 }
                               </Box>
                               <Box sx={{ padding: '0 12px 12px' }}>
                                 <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                   Change Required
                                 </Typography>
                                 <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                   {moc?.mocForm?.requestForm.change_required}
                                 </Typography>
                               </Box>
                               <Box sx={{ padding: '0 12px 12px' }}>
                               <Chip
 label={
   moc?.mocForm?.conclusion === "Quality Head"
     ? "Critical to Quality"
     : moc?.mocForm?.conclusion === "Safety Head"
     ? "Critical to Safety"
     : moc?.mocForm?.conclusion // Default to the original conclusion text if it doesn’t match
 }
 style={{
   backgroundColor: "white",
   color: "#B51849",
   fontFamily: "inherit",
   fontSize: "10px",
   padding: "2px 0",
   border: "1px solid #B51849",
   fontWeight: 600,
   width: "fit-content",
   height: '24px'
 }}
/>

                               </Box>
                               <React.Fragment>
                                 <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                   <Box style={{ display: "flex" }}>
                                     <img
                                       src={TimeLine}
                                       alt="Timeline"
                                       style={{ marginRight: "10px" }}
                                       onClick={(event) => toggleAccordionTimeline(event, moc.moc_id)}
                                     />
                                     <Typography
                                       style={{
                                         color: "#616161",
                                         fontFamily: "inherit",
                                         fontSize: "14px",
                                         fontWeight: 500,
                                       }}
                                     >
                                       {moment(moc.mocForm.requestForm.moc_date).format("DD-MMM-YYYY")}
                                     </Typography>
                                   </Box>
                                   <AvatarGroup>
                                     {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_name ?
                                       <Box style={{ position: "relative" }}>
                                         <Tooltip title={moc?.mocForm?.plant_name}>
                                           <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                             {`${moc?.mocForm?.plant_name.split(" ").pop().charAt(0)}${moc?.mocForm?.plant_name.charAt(0)}`}
                                           </Avatar>
                                         </Tooltip>
                                       </Box> : null}

                                     {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_name ?
                                       <Box style={{ position: "relative" }}>
                                         <Tooltip title={moc?.mocForm?.safety_name}>
                                           <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                             {`${moc?.mocForm?.safety_name.split(" ").pop().charAt(0)}${moc?.mocForm?.safety_name.charAt(0)}`}
                                           </Avatar>
                                         </Tooltip>
                                       </Box> : null}

                                     {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_name ?
                                       <Box style={{ position: "relative" }}>
                                         <Tooltip title={moc?.mocForm?.quality_name}>
                                           <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }} >
                                             {`${moc?.mocForm?.quality_name.split(" ").pop().charAt(0)}${moc?.mocForm?.quality_name.charAt(0)}`}
                                           </Avatar>
                                         </Tooltip>
                                       </Box> : null}

                                     {moc?.mocForm?.authority_name ?
                                       <Box style={{ position: "relative" }}>
                                         <Tooltip title={moc?.mocForm?.authority_name}>
                                           <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                             {`${moc?.mocForm?.authority_name.split(" ").pop().charAt(0)}${moc?.mocForm?.authority_name.charAt(0)}`}
                                           </Avatar>
                                         </Tooltip>
                                       </Box> : null
                                     }

                                     {moc?.mocForm?.created_by ?
                                       <Box style={{ position: "relative" }}>
                                         <Tooltip title={moc?.mocForm?.created_by}>
                                           <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                             {`${moc?.mocForm?.created_by.split(" ").pop().charAt(0)}${moc?.mocForm?.created_by.charAt(0)}`}
                                           </Avatar>
                                         </Tooltip>
                                       </Box> : null
                                     }
                                   </AvatarGroup>
                                 </Box>
                                 {expandedCardId === moc.moc_id && conclusionTimeline.length > 0 && (
                                   <Timeline sx={{
                                     [`& .${timelineItemClasses.root}:before`]: {
                                       flex: 0,
                                       padding: 0,
                                     },
                                   }}>
                                     <Box>
                                       <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                         Conclusion
                                       </Typography>
                                     </Box>
                                     {conclusionTimeline.map((action, index) => (
                                       <TimelineItem key={index}>
                                         <TimelineSeparator>
                                           <TimelineDot color="primary" />
                                           {index !== conclusionTimeline.length - 1 && <TimelineConnector />}
                                         </TimelineSeparator>
                                         <TimelineContent>
                                           <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                             {action.status + " " + "by" + " " + action.user_approval_name}
                                           </Typography>
                                           <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                             {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                           </Typography>
                                         </TimelineContent>
                                       </TimelineItem>
                                     ))}
                                   </Timeline>
                                 )}
                                 {/* {expandedCardId === moc.moc_id && actionTimeline.length > 0 && (
                                   <Timeline sx={{
                                     [`& .${timelineItemClasses.root}:before`]: {
                                       flex: 0,
                                       padding: 0,
                                     },
                                   }}>
                                     <Box>
                                       <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                         Trials
                                       </Typography>
                                     </Box>
                                     {actionTimeline.map((action, index) => (
                                       <TimelineItem key={index}>
                                         <TimelineSeparator>
                                           <TimelineDot color="primary" />
                                           {index !== actionTimeline.length - 1 && <TimelineConnector />}
                                         </TimelineSeparator>
                                         <TimelineContent>
                                           <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                             {action.status + " " + "by" + " " + action.user_approval_name}
                                           </Typography>
                                           <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                             {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                           </Typography>
                                         </TimelineContent>
                                       </TimelineItem>
                                     ))}
                                   </Timeline>
                                 )} */}
                                 {expandedCardId === moc.moc_id && implementTimeline.length > 0 && (
                                   <Timeline sx={{
                                     [`& .${timelineItemClasses.root}:before`]: {
                                       flex: 0,
                                       padding: 0,
                                     },
                                   }}>
                                     <Box>
                                       <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                         Implement
                                       </Typography>
                                     </Box>
                                     {implementTimeline.map((action, index) => (
                                       <TimelineItem key={index}>
                                         <TimelineSeparator>
                                           <TimelineDot color="primary" />
                                           {index !== implementTimeline.length - 1 && <TimelineConnector />}
                                         </TimelineSeparator>
                                         <TimelineContent>
                                           <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                             {action.status + " " + "by" + " " + action.user_approval_name}
                                           </Typography>
                                           <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                             {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                           </Typography>
                                         </TimelineContent>
                                       </TimelineItem>
                                     ))}
                                   </Timeline>
                                 )}
                               </React.Fragment>
                             </CardContent>
                           </Card>
                         </React.Fragment>
                       ) : null
                   ))}
                   {filterCompleteData.length === 9 && filterCompleteData.length - 1 && (
                     <Box>
                       {error && (
                         <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                           {error}
                         </Box>
                       )}
                       {/* {loading ? (
                         <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                           <CircularProgress />
                         </Box>
                       ) : (
                         <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 30 }} >
                           {searchText
                             ? renderRecords(filteredSearchRecords)
                             : renderRecords(displayedRecords)}
                           {visibleRecords < totalCompletedRecords && (
                             <Box style={{ marginTop: "20px", display: "flex", flex: 1 }} >
                               <Button
                                 variant="contained"
                                 style={{ width: "200px", backgroundColor: "#00448B", textTransform: "none", fontSize: 24, color: "white", borderRadius: "5px", textAlign: "center" }}
                                 onClick={handleClickSeeMore}>
                                 View More
                               </Button>
                             </Box>
                           )}
                           <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
                             {visibleRecords <= totalCompletedRecords ? `${visibleRecords} of ${totalCompletedRecords}` : `${totalCompletedRecords} of ${totalCompletedRecords}`}
                           </Typography>
                         </Box>
                       )} */}
                     </Box>
                   )}
                   {filterCompleteData.length === 0 && <p>No records found.</p>}
                 </Grid>
                )}

                {selectedTab === 5 && (
                  <Grid item md={3} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                  <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>REJECTED</Typography>
                  {filterRejectData.map(
                    (moc, index) =>
                      ( // User
                        (moc?.mocForm?.conclusion === "Quality Head" ?
                          (
                            user === moc.user_sgid
                          ) :
                          moc?.mocForm?.conclusion === "Safety Head" ?
                            (
                              user === moc.user_sgid
                            ) :
                            moc?.mocForm?.conclusion === "Safety & Quality" &&
                            (
                              user === moc.user_sgid
                            )
                        )
                        // Non Critical
                        || (moc?.mocForm?.conclusion === "Non Critical" && user === moc.user_sgid)

                        // Admin
                        // Quality Head
                        || (
                          // Section 1 Approval Flow
                          ((moc?.mocForm?.conclusion === "Quality Head" ||
                            moc?.mocForm?.conclusion === "Safety Head" ||
                            moc?.mocForm?.conclusion === "Safety & Quality") &&
                            (moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === false ||
                              moc?.changeImplementation?.implement_status === "rejected" && moc?.changeImplementation?.admin_approval_status === false || moc?.changeImplementation?.implement_status === "rejected") &&
                            user === moc.approver_sgid)
                        )
                        // Non Critical
                        || (moc?.mocForm?.conclusion === "Non Critical" &&   (moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === false ||
                          moc?.changeImplementation?.implement_status === "rejected" && moc?.changeImplementation?.admin_approval_status === false) &&
                        user === moc.approver_sgid)

                        // Section 1 Approval Flow
                        // || (moc?.mocForm?.quality_approval_status === false && userName === moc?.mocForm?.quality_name)
                        || (moc?.mocForm?.conclusion === "Safety & Quality" && moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && userName === moc?.mocForm?.quality_name)
                        || (moc?.mocForm?.conclusion === "Quality Head" && moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === false && userName === moc?.mocForm?.quality_name)
                        // Section 2 Approval Flow
                        // Section 3 Approval Flow
                        || (moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === false && userName === moc?.mocForm?.quality_name)

                        // Section 1 Approval Flow
                        // || (moc?.mocForm?.safety_approval_status === false && userName === moc?.mocForm?.safety_name)
                        || (moc?.mocForm?.conclusion === "Safety & Quality" && moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false && userName === moc?.mocForm?.safety_name)
                        || (moc?.mocForm?.conclusion === "Safety Head" && moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === false && userName === moc?.mocForm?.safety_name)
                        // Section 2 Approval Flow
                        // Section 3 Approval Flow
                        || (moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === false && userName === moc?.mocForm?.safety_name)

                        // Section 1 Approval Flow
                        || (moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_approval_status === false && moc?.mocForm?.plant_check === 'Yes' && userName === moc?.mocForm?.plant_name)
                        || (moc?.mocForm?.conclusion === "Quality Head" && moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_approval_status === true && moc?.mocForm?.plant_approval_status === false && userName === moc?.mocForm?.plant_name)
                        || (moc?.mocForm?.conclusion === "Safety Head" && moc?.mocForm?.submission_status === "rejected" && moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_approval_status === true && moc?.mocForm?.plant_approval_status === false && userName === moc?.mocForm?.plant_name)
                        // || (moc?.mocForm?.plant_approval_status === false && moc?.mocForm?.plant_check === 'Yes' && userName === moc?.mocForm?.plant_name)
                        // Section 3 Approval Flow
                        || (moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.quality_approval_status === true && moc?.changeImplementation?.plant_approval_status===false && userName === moc?.mocForm?.plant_name)
                        || (moc?.changeImplementation?.admin_approval_status === true && moc?.changeImplementation?.safety_approval_status === true && moc?.changeImplementation?.plant_approval_status===false && userName === moc?.mocForm?.plant_name)
                      )
                        ? (
                          <React.Fragment key={moc.id}>
                            <Card key={moc.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                              <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', padding: "12px" }}>
                                  {moc?.mocForm?.moc_no ?
                                    <Box>
                                      <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                        MOC NO.
                                      </Typography>
                                      <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "5px" }}>
                                        {moc?.mocForm?.moc_no}
                                      </Typography>
                                    </Box> : <></>
                                  }
                                  <Button
                                    sx={{
                                      padding: '0',
                                      minWidth: '30px'
                                    }}
                                    id="basic-button"
                                    onClick={(event) => handleMenuClick(event, moc.id)}
                                  >
                                    <MoreVertIcon sx={{ color: '#000' }} />
                                  </Button>

                                  {showMenu && openedMenuId === moc.id && (
                                    <div
                                      ref={menuRef}
                                      style={{
                                        position: "absolute",
                                        top: 30,
                                        right: 20,
                                        width: 180,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        borderRadius: 4,
                                        border: "1px solid #B51849",
                                        zIndex: 10,
                                      }}
                                    >
                                      {!showActionPlan ? (
                                        <div
                                          onClick={() => handleMenuItemClick("edit", moc)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}
                                        >
                                          <>
                                            <img
                                              src={Edit}
                                              alt="Edit Icon"
                                              style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                            />
                                            <Typography>Edit</Typography>
                                          </>
                                        </div>
                                      ) : null}

                                      {showActionPlan && (
                                        <div
                                          onClick={() => handleMenuItemClick("actionPlan", moc.id)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                          <img
                                            src={Edit}
                                            alt="Action Plan"
                                            style={{
                                              verticalAlign: "middle",
                                              marginRight: "5px",
                                              marginBottom: "5px",
                                              cursor: "pointer",
                                            }}
                                          />Action Plan
                                        </div>)
                                      }
                                      {showImplement && (
                                        <div
                                          onClick={() => handleMenuItemClick("implementMOC", moc.id)}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                          <img
                                            src={Edit}
                                            alt="Implement MOC"
                                            style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }} />
                                          Implement MOC
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Box>
                                <Box sx={{ padding: '0 12px 12px' }}>
                                  <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                    Change Required
                                  </Typography>
                                  <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                    {moc?.mocForm?.requestForm.change_required}
                                  </Typography>
                                </Box>
                                <Box sx={{ padding: '0 12px 12px' }}>
                                <Chip
label={
  moc?.mocForm?.conclusion === "Quality Head"
    ? "Critical to Quality"
    : moc?.mocForm?.conclusion === "Safety Head"
    ? "Critical to Safety"
    : moc?.mocForm?.conclusion // Default to the original conclusion text if it doesn’t match
}
style={{
  backgroundColor: "white",
  color: "#B51849",
  fontFamily: "inherit",
  fontSize: "10px",
  padding: "2px 0",
  border: "1px solid #B51849",
  fontWeight: 600,
  width: "fit-content",
  height: '24px'
}}
/>

                                </Box>
                                <React.Fragment>
                                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                    <Box style={{ display: "flex" }}>
                                      <img
                                        src={TimeLine}
                                        alt="Timeline"
                                        style={{ marginRight: "10px" }}
                                        onClick={(event) => toggleAccordionTimeline(event, moc.moc_id)}
                                      />
                                      <Typography
                                        style={{
                                          color: "#616161",
                                          fontFamily: "inherit",
                                          fontSize: "14px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {moment(moc.mocForm.requestForm.moc_date).format("DD-MMM-YYYY")}
                                      </Typography>
                                    </Box>
                                    <AvatarGroup>
                                      {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.plant_check === "Yes" && moc?.mocForm?.plant_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.plant_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                              {`${moc?.mocForm?.plant_name.split(" ").pop().charAt(0)}${moc?.mocForm?.plant_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null}

                                      {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.safety_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.safety_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                              {`${moc?.mocForm?.safety_name.split(" ").pop().charAt(0)}${moc?.mocForm?.safety_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null}

                                      {moc?.mocForm?.admin_approval_status === true && moc?.mocForm?.quality_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.quality_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }} >
                                              {`${moc?.mocForm?.quality_name.split(" ").pop().charAt(0)}${moc?.mocForm?.quality_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null}

                                      {moc?.mocForm?.authority_name ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.authority_name}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                              {`${moc?.mocForm?.authority_name.split(" ").pop().charAt(0)}${moc?.mocForm?.authority_name.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null
                                      }

                                      {moc?.mocForm?.created_by ?
                                        <Box style={{ position: "relative" }}>
                                          <Tooltip title={moc?.mocForm?.created_by}>
                                            <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px" }}>
                                              {`${moc?.mocForm?.created_by.split(" ").pop().charAt(0)}${moc?.mocForm?.created_by.charAt(0)}`}
                                            </Avatar>
                                          </Tooltip>
                                        </Box> : null
                                      }
                                    </AvatarGroup>
                                  </Box>
                                  {expandedCardId === moc.moc_id && conclusionTimeline.length > 0 && (
                                    <Timeline sx={{
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}>
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                          Conclusion
                                        </Typography>
                                      </Box>
                                      {conclusionTimeline.map((action, index) => (
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            {index !== conclusionTimeline.length - 1 && <TimelineConnector />}
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {action.status + " " + "by" + " " + action.user_approval_name}
                                            </Typography>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </Timeline>
                                  )}
                                  {/* {expandedCardId === moc.moc_id && actionTimeline.length > 0 && (
                                    <Timeline sx={{
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}>
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                          Trials
                                        </Typography>
                                      </Box>
                                      {actionTimeline.map((action, index) => (
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            {index !== actionTimeline.length - 1 && <TimelineConnector />}
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {action.status + " " + "by" + " " + action.user_approval_name}
                                            </Typography>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </Timeline>
                                  )} */}
                                  {expandedCardId === moc.moc_id && implementTimeline.length > 0 && (
                                    <Timeline sx={{
                                      [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                      },
                                    }}>
                                      <Box>
                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }}>
                                          Implement
                                        </Typography>
                                      </Box>
                                      {implementTimeline.map((action, index) => (
                                        <TimelineItem key={index}>
                                          <TimelineSeparator>
                                            <TimelineDot color="primary" />
                                            {index !== implementTimeline.length - 1 && <TimelineConnector />}
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {action.status + " " + "by" + " " + action.user_approval_name}
                                            </Typography>
                                            <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                              {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                            </Typography>
                                          </TimelineContent>
                                        </TimelineItem>
                                      ))}
                                    </Timeline>
                                  )}
                                </React.Fragment>
                              </CardContent>
                            </Card>
                          </React.Fragment>
                        ) : null
                  )}
                  {filterRejectData.length === 9 && filterRejectData.length - 1 && (
                    <Box>
                      {error && (
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {error}
                        </Box>
                      )}
                      {/* {loading ? (
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 30 }} >
                          {searchText
                            ? renderRecords(filteredSearchRecords)
                            : renderRecords(displayedRecords)}
                          {visibleRecords < totalRejectedRecords && (
                            <Box style={{ marginTop: "20px", display: "flex", flex: 1 }} >
                              <Button
                                variant="contained"
                                style={{ width: "200px", backgroundColor: "#00448B", textTransform: "none", fontSize: 24, color: "white", borderRadius: "5px", textAlign: "center" }}
                                onClick={handleClickSeeMore}>
                                View More
                              </Button>
                            </Box>
                          )}
                          <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
                            {visibleRecords <= totalRejectedRecords ? `${visibleRecords} of ${totalRejectedRecords}` : `${totalRejectedRecords} of ${totalRejectedRecords}`}
                          </Typography>
                        </Box>
                      )} */}
                    </Box>
                  )}
                  {filterRejectData.length === 0 && <p>No records found.</p>}
                </Grid>
                )}

              </div>
            )}

        </div>
      </div>

      {editDialogOpen && <RequestForm
        open={editDialogOpen}
        onClose={dialogClose}
      />}
      <NewRequest isOpen={newDialogRequestOpen} onClose={newDialogRequestClose} />
    </>
  );
};

export default ManageMoc;
import React, { useState, useEffect } from "react";
import { Typography, Grid, Skeleton, Card } from "@mui/material";
import CardsCms from "./CardsCms";
import ChartCms from "./ChartCms";

const DashboardItem = () => {
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    //console.log(storedUserData, "storedUserData11")
    const profileName = storedUserData.FName + " " + storedUserData.LName;

    useEffect(() => {
        // Assuming you have the data stored in localStorage as described
        const completedCmsCount = localStorage.getItem("completedCms") || 0;
        const inProgressCmsCount = localStorage.getItem("inProgressCms") || 0;
        const pendingCmsCount = localStorage.getItem("pendingCms") || 0;
        const rejectedCmsCount = localStorage.getItem("rejectedCms") || 0;

        const seriesData = {
            "All CMS": {
                "Pending CMS": parseInt(pendingCmsCount),
                "In Progress CMS": parseInt(inProgressCmsCount),
                "Completed CMS": parseInt(completedCmsCount),
                "Rejected CMS": parseInt(rejectedCmsCount),
            },
        };

        setGraphData(seriesData);
        setIsLoading(false);
    }, []);
    const allCmsCount = localStorage.getItem("allCms") || 0;
    const completedCmsCount = localStorage.getItem("completedCms") || 0;
    const inProgressCmsCount = localStorage.getItem("inProgressCms") || 0;
    const pendingCmsCount = localStorage.getItem("pendingCms") || 0;
    const rejectedCmsCount = localStorage.getItem("rejectedCms") || 0;

    return (
        <div style={{ justifyContent: 'center', padding: '16px' }}>
            {/* <Header pageTitle="Dashboard" isDashboard={true} /> */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography
                        id="user_name"
                        style={{ fontSize: "20px", fontWeight: 600 }}
                    >
                        Welcome {profileName}
                    </Typography>
                </Grid>
            </Grid>
            {isLoading &&
                <div className="skeleton-dashboard" style={{ width: "100%" }}>
                    <Grid container style={{ justifyContent: "space-between", marginTop: "30px" }}>
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
                    </Grid>
                    <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                        <Skeleton style={{ width: "85%", height: 400, transform: "none" }} />
                    </Grid>
                </div>}

            <Grid item xs={12}>
                <Grid>
                    <CardsCms
                        allCmsCount={allCmsCount}
                        completedCmsCount={completedCmsCount}
                        pendingCmsCount={pendingCmsCount}
                        inProgressCmsCount={inProgressCmsCount}
                        rejectedCmsCount={rejectedCmsCount}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid
                        item
                        xl={12}
                        md={12}
                        xs={12}
                        className="pl-md"
                        style={{ marginTop: "24px" }}
                    >
                        <Card
                            style={{
                                marginRight: "0px",
                                boxShadow: "none",
                                backgroundColor: "#FCFCFC",
                                border: "1px solid #E8E8E8",
                                padding: 10,
                            }}
                        >
                            {Object.keys(graphData).length > 0 ? (
                                <ChartCms series={graphData} />
                            ) : (
                                <div>No data available</div>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}

export default DashboardItem;
import React, { useState, useEffect, useRef } from "react";
import { Typography, FormControl, Radio, RadioGroup, FormControlLabel, Divider, Checkbox, InputLabel, Button, CircularProgress, Grid, Box, DialogTitle, DialogContent } from "@mui/material";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { impactChangeData, editCMSData } from "../recoil/atoms";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";
import axios from "axios";
import API from "../Services/API";
import BackIcon from "../Asset/Icons/back_icon.svg";

const ImpactChange = ({ dataImpacted, onBack }) => {
    const [impactData, setImpactData] = useRecoilState(impactChangeData);
    const [newDialogImpactOpen, setNewDialogImpactOpen] = useState('true');
    const [isEditCms] = useRecoilState(editCMSData);
    const [save, setSave] = useState(false);
    const [errors, setErrors] = useState(false);
    const [saveMessage, setSaveMessage] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [showResubmitPopup, setShowResubmitPopup] = useState(false);
    // const [uploadDocument, setUploadDocument] = useState("");
    const [uploadDocuments, setUploadDocuments] = useState(Array.from({ length: 27 }, () => ""));
    const [selectedValue, setSelectedValue] = useState(null);
    const [questionAssess, setQuestionAssess] = useState([]);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };
        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopup(setShowPopup, 1000);
                }, 2000);
            }
        };
        handlePopups(showSuccessPopup, setShowSuccessPopup);
        handlePopups(showUpdatePopup, setShowUpdatePopup);
        handlePopups(showSubmitPopup, setShowSubmitPopup);
        handlePopups(showResubmitPopup, setShowResubmitPopup);
    }, [showSuccessPopup, showUpdatePopup, showSubmitPopup, showResubmitPopup]);

    const CPQuestionsStyle = {
        fontWeight: 550,
        fontSize: 16,
        fontStyle: "normal",
        fontFamily: "Inter, sans-serif",
        color: "black",
        lineHeight: 1.5,
        textTransform: "capitalize",
        marginTop: "10px",
    };

    // const questions = [
    //     "Change in BOM",
    //     "Jig / Tool / Gauge Drawings",
    //     "Process Flow Chart",
    //     "PFMEA",
    //     "Control Plan / PSS",
    //     "S.O.P/ Visual Aids",
    //     "Process qualification",
    //     "Actions on  Sub Contractor/Vendor",
    //     "PSS for receiving inspection",
    //     "Parts already produced and available on stock",
    //     "Production Trial Run / operator training",
    //     "Setting approval / Machine control chart",
    //     "Checking Aids",
    //     "MSA /SPC",
    //     "Homolgation",
    //     "DVP testing",
    //     "Calibration history card of Part specific gauges",
    //     "PSS  for Final Insp/Product Audit",
    //     "Packing instructions / Invoicing / New SAP code",
    //     "Final Inspection Report",
    //     "Sub-Contractor PPAP",
    //     "Validation of BOM & Campaign creation",
    //     "Customer PPAP",
    //     "Cost implications/ benefits",
    //     "Any additional Resources requirements",
    //     "Horizontal Deployment",
    //     "Forecast to be released in New SAP code"
    // ];

    useEffect(() => {
        // Initialize affect array based on the number of questions
        setImpactData((prev) => ({
            ...prev,
            affects: Array(questionAssess.length).fill(undefined),
            actions: Array(questionAssess.length).fill(""),
            responsibility: Array(questionAssess.length).fill(""),
            date: Array(questionAssess.length).fill(""),
            remarks: Array(questionAssess.length).fill(""),
        }));

        const fetchAssessQuestion = async () => {
            setInitialLoad(true);
            try {
                const response = await axios.get(`${API.GET_IMPACT_CMS_QUESTIONS}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
                const data = response.data
                    .filter((item) => item.tag === "impact")
                    .map(({ id, questions, tag, question_no }) => ({ id, questions, tag, question_no }))
                    .sort((a, b) => a.question_no - b.question_no); // Ensure sorting is correct if needed

                setQuestionAssess(data);
                //console.log(data, "data125")
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
            setInitialLoad(false);
        };

        fetchAssessQuestion();
    }, []);


    const handleRadioChange = (index, value) => {
        const updatedAnswers = [...impactData.affects];
        //console.log(updatedAnswers, "updated108")
        updatedAnswers[index] = value;
        setImpactData((prev) => ({
            ...prev,
            affects: updatedAnswers,
        }));
        setSelectedValue(value);
        // setErrors(false);
    }

    const onHandleChange = (event, field, index) => {
        let updatedForm = { ...impactData, [field]: [...impactData[field]] };
        updatedForm[field][index] = event.target.value;
        setImpactData(updatedForm);
        setErrors(false);
    };

    if (!impactData.date) {
        //console.log("imp322", impactData)
        const initialDates = new Array(questionAssess.length).fill("");
        setImpactData((prev) => ({
            ...prev,
            date: initialDates,
        }));
    }

    const handleDateChange = (event, index) => {
        const inputDate = event.target.value;
        const selectedDate = new Date(inputDate);
        const today = new Date().toISOString().split("T")[0];
        let updatedDates = [...impactData.date];
        // Allow clearing the date
        if (inputDate === "") {
            updatedDates[index] = ""; // Clear the date
        } else if (!isNaN(selectedDate.getTime()) && inputDate <= today) {
            updatedDates[index] = inputDate; // Set the valid date
        } else {
            // Handle invalid date input if needed
        }
        setImpactData((prev) => ({
            ...prev,
            date: updatedDates,
        }));
    };

    const handleRemarksChange = (index, event) => {
        const updatedRemarks = [...impactData.remarks];
        updatedRemarks[index] = event.target.value;
        setImpactData({
            ...impactData,
            remarks: updatedRemarks,
        });
    };

    const impactChanges = {
        impact_change: [],
    };

    questionAssess.forEach((impactchanges, index) => {
        impactChanges.impact_change.push({
            impactchanges,
            affects: impactData.affects[index],
            actions: impactData.actions[index],
            responsibility: impactData.responsibility[index],
            date: impactData.date[index],
            remarks: impactData.remarks[index],
            document: impactData.document[index],
        });
    });

    const handleImpactSave = async () => {
        try {
            // Check if the affects array is defined and all radio buttons are selected
            if (
                !Array.isArray(impactData.affects) || // Check if affects is an array
                impactData.affects.includes(undefined)
                // || // Check if it includes undefined
                // impactData.affects.length !== 28 // Check if length is exactly 28
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            }

            let hasError = false;

            impactData.affects.forEach((affect, index) => {
                if (affect === "Yes") {
                    // Check for mandatory fields for 'Yes' responses
                    if (
                        !impactData.actions[index] ||
                        !impactData.responsibility[index] ||
                        !impactData.date[index] ||
                        !impactData.remarks[index] ||
                        !(impactData.document[index] &&
                            Array.isArray(impactData.document[index]) &&
                            impactData.document[index].length > 0)
                    ) {
                        hasError = true;
                    }
                }
            });

            // If any 'Yes' is selected but required fields are empty, set error states
            if (hasError) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            // Proceed with the API call if no errors
            const response = await axios.post(API.INSERT_IMPACT_CHANGE, impactChanges, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });

            if (response.status === 200) {
                const impactID = response.data.identifiers[0].id;
                localStorage.setItem("impactID", impactID);
                localStorage.setItem("isImpactChangeSave", true);
                await cmsForm(impactID);
                setSave(true);
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };


    const cmsForm = async () => {
        const updateCmsDet = localStorage.getItem("cmsID");
        //console.log(updateCmsDet, "updateMocDet228");
        const requestID = localStorage.getItem("requestID");
        const changeRequireID = localStorage.getItem("changeRequireID");
        //console.log(changeRequireID, "changeRequireID");
        const impactID = localStorage.getItem("impactID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const userSGID = storedUserData.SGID;
        const userName = storedUserData.FName + " " + storedUserData.LName;
        const userMail = storedUserData.MailId;
        try {
            const cmsData = {
                id: updateCmsDet,
                user_id: userSGID,
                updated_at: new Date(),
                created_by: userName,
                doc_ref: "F/CFT/SGSI/01",
                rev_no: "03",
                rev_date: new Date(),
                change_request_id: requestID,
                change_required_id: changeRequireID,
                impact_change_id: impactID,
                submission_status: requestID && changeRequireID && impactID ? "pending" : "",
                user_mail: userMail
            };
            const cmsResponse = await axios.put(
                `${API.UPDATE_CMS_FORM}?id=${updateCmsDet}`,
                cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            //console.log("updateCmsDet217", updateCmsDet);
            //console.log("cmsData217", cmsData);
            if (cmsResponse.status === 200) {
                //console.log("update conclusion", cmsResponse);
                await updateCmsApprovals(updateCmsDet);
                setSave(true);
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const updateCmsApprovals = async () => {
        const updateMocDet = localStorage.getItem("cmsID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData79999");
        //console.log(updateMocDet, "updateMocDet");
        const approverSGID = storedUserData.N1SGID;
        const userSGID = updateMocDet ? storedUserData.SGID : "";
        const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : " ";
        const cmsApprovalID = localStorage.getItem("cmsApprovalID");
        try {
            const cmsData = {
                cms_id: updateMocDet,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                status: "pending",
            };
            const cmsResponse = await axios.put(`${API.UPDATE_CMS_APPROVALS}?id=${cmsApprovalID}`, cmsData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (cmsResponse.status === 200) {
                //console.log("Inserted into moc table:", cmsResponse);
                const cmsApprovalID = cmsResponse.data["identifiers"][0].id;
                //console.log("Inserted cmsApprovalID", cmsApprovalID);
                localStorage.setItem("cmsApprovalID", cmsApprovalID);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const handleImpactNext = () => {
        try {
            const isImpactChangeSave = JSON.parse(localStorage.getItem("isImpactChangeSave"));
            let hasError = false;
            impactData.affects.forEach((affect, index) => {
                if (affect === "Yes") {
                    if (!impactData.actions[index] || !impactData.responsibility[index] || !impactData.date[index] || !impactData.remarks[index] || !(impactData.document[index] &&
                        Array.isArray(impactData.document[index]) &&
                        impactData.document[index].length > 0)) {
                        hasError = true;
                    }
                }
            });
            if (hasError) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            if (save || isImpactChangeSave || isEditCms) {
                dataImpacted(newDialogImpactOpen);
                // navigate("/notification");
            } else {
                setSaveMessage("Failed to save. Please try again.");
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const handleImpactUpdate = async (index) => {
        const storedMocDataString = localStorage.getItem("cmsDataByID");
        const storedMocData = JSON.parse(storedMocDataString);
        const impactChangeID = storedMocData?.cmsForm?.impact_change_id;
        try {
            // Check if the affects array is defined and all radio buttons are selected
            if (
                !Array.isArray(impactData.affects) || // Check if affects is an array
                impactData.affects.includes(undefined)
                //  || // Check if it includes undefined
                // impactData.affects.length !== 28 // Check if length is exactly 28
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            }

            let hasError = false;

            impactData.affects.forEach((affect, index) => {
                if (affect === "Yes") {
                    // Check for mandatory fields for 'Yes' responses
                    if (
                        !impactData.actions[index] ||
                        !impactData.responsibility[index] ||
                        !impactData.date[index] ||
                        !impactData.remarks[index] ||
                        !(impactData.document[index] &&
                            Array.isArray(impactData.document[index]) &&
                            impactData.document[index].length > 0)
                    ) {
                        hasError = true;
                    }
                }
            });

            // If any 'Yes' is selected but required fields are empty, set error states
            if (hasError) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            // const impactChanges = impactData.affects.map((affect, i) => ({
            //     date: impactData.date[i],
            //     actions: impactData.actions[i],
            //     affects: affect,
            //     remarks: impactData.remarks[i],
            //     document: impactData.document[i],
            //     responsibility: impactData.responsibility[i],
            //     impactchanges: { id: impactChangeID } // or however you need to structure this
            // }));

            const impactChanges = [];

            questionAssess.forEach((impactchanges, index) => {
                const changeEntry = {
                    affects: impactData.affects[index],
                    impactchanges: impactchanges,
                };

                // Add additional properties only if they exist
                if (impactData.actions[index]) changeEntry.actions = impactData.actions[index];
                if (impactData.responsibility[index]) changeEntry.responsibility = impactData.responsibility[index];
                if (impactData.date[index]) changeEntry.date = impactData.date[index];
                if (impactData.remarks[index]) changeEntry.remarks = impactData.remarks[index];
                if (impactData.document[index]) changeEntry.document = impactData.document[index];

                // Add the entry to the array
                impactChanges.push(changeEntry);
            });

            // Now you can filter out any entries that don't match your desired structure
            const formattedImpactChanges = impactChanges.map(entry => {
                return {
                    affects: entry.affects || "No", // Default to "No" if undefined
                    impactchanges: entry.impactchanges,
                    ...(entry.actions && { actions: entry.actions }),
                    ...(entry.responsibility && { responsibility: entry.responsibility }),
                    ...(entry.date && { date: entry.date }),
                    ...(entry.remarks && { remarks: entry.remarks }),
                    ...(entry.document && { document: entry.document })
                };
            });
            //console.log(formattedImpactChanges, "formattedImpactChanges475");
            const cmsResponse = await axios.put(
                `${API.UPDATE_IMPACT_CHANGE}?id=${impactChangeID}`,
                formattedImpactChanges,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            if (cmsResponse.status === 200) {
                setShowResubmitPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const handleCaptureGallery = (index) => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            fileInputRef.current.onchange = (e) => {
                const selectedFiles = e.target.files;
                const currentFiles = impactData.document[index] || [];
                // Check if there are already 5 files for this question
                if (currentFiles.length + selectedFiles.length > 5) {
                    alert("You can upload a maximum of 5 files per question.");
                    return; // Do not proceed if file count exceeds 5
                }
                // Proceed with uploading the files if it's within the limit
                setUploadedFile({ target: { files: selectedFiles } }, index);
            };
        }
    };

    const displayImage = (files) => {
        const imageBeforeArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageBeforeArray.push(e.target.result);
                if (imageBeforeArray.length === files.length) {
                    // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const setUploadedFile = async (event, questionIndex) => {
        const files = event.target.files;
        displayImage(files);

        if (files.length > 0) {
            const form = new FormData();
            Array.from(files).forEach(file => {
                form.append('file', file, file.name);
            });

            try {
                const response = await axios.post(API.UPLOAD_DOCUMENTS_IMPACT, form, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });

                // Get the file paths from the response
                const result = response.data.filePaths;

                // Update the document array for the specific question
                const updatedDocument = [...impactData.document];
                if (!updatedDocument[questionIndex]) {
                    updatedDocument[questionIndex] = [];
                }

                // Add the new files to the existing documents for this question
                updatedDocument[questionIndex] = [...updatedDocument[questionIndex], ...result];

                setImpactData({
                    ...impactData,
                    document: updatedDocument,
                });
            } catch (error) {
                console.error('Failed to upload images:', error);
            }
        }
    };

    const handleDelete = (questionIndex, fileIndex) => {
        const updatedDocument = [...impactData.document];

        // Delete the file by filtering out the file at the given index
        updatedDocument[questionIndex] = updatedDocument[questionIndex].filter((_, i) => i !== fileIndex);

        setImpactData(prevData => ({
            ...prevData,
            document: updatedDocument,
        }));
    };

    const handleDownloadDocument = async (file) => {
        const parts = file.split(/[\\/]/);
        try {
            const response = await axios.get(
                API.DOWNLOAD_DOCUMENTS + `/${parts[parts.length - 1]}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    responseType: 'blob', // Ensure the response type is set to 'blob'
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', parts[parts.length - 1]); // Set the correct filename
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL after the file is downloaded to free up resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file", error);
        }
    };

    useEffect(() => {
        if (isEditCms) {
            const getImpactChange = async () => {
                const storedMocDataString = localStorage.getItem("cmsDataByID");
                const storedMocData = JSON.parse(storedMocDataString);
                //console.log(storedMocData, "storedMocData319")
                const impactID = storedMocData?.cmsForm?.impact_change_id;
                //console.log(impactID, "impactID")
                try {
                    const response = await axios.get(
                        `${API.GET_CMS_IMPACT_CHANGE}?id=${impactID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`
                            }
                        }
                    );
                    const impactFormDetails = await response.data;
                    //console.log(response, "response329")
                    //console.log(impactFormDetails, "requestFormDetails330")
                    let getAffects = [];
                    let getActions = [];
                    let getResponsibility = [];
                    let getDate = [];
                    let getRemarks = [];
                    let getDocument = [];
                    impactFormDetails.impact_change.map((data) => {
                        //console.log(data.affects, "data.affects507");
                        getAffects.push(data.affects);
                        getActions.push(data.actions);
                        getResponsibility.push(data.responsibility);
                        getDate.push(data.date);
                        getRemarks.push(data.remarks);
                        getDocument.push(data.document)
                        return null;
                    });
                    setImpactData((prev) => {
                        //console.log(prev, "pre state");
                        return {
                            ...prev,
                            affects: getAffects,
                            actions: getActions,
                            responsibility: getResponsibility,
                            date: getDate,
                            remarks: getRemarks,
                            document: getDocument,
                        };
                    });
                } catch (error) {
                    console.error("Error fetching MOC details:", error);
                }
            };
            getImpactChange();
        }
    }, [setImpactData, isEditCms]);

    // const cmsNumberData = localStorage.getItem("cmsNumber");

    const handleUploadClick = (questionIndex) => {
        if (!uploadDocuments[questionIndex]) {
            handleCaptureGallery(questionIndex);
        }
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = storedUserData.SGID;
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    const cmsNo = cmsDetData?.cmsForm?.cms_no === null;
    //console.log(cmsNo, "cmsNo576")
    //console.log(cmsDetData, "cmsDetData577")
    //console.log((JSON.parse(localStorage.getItem("cmsDataByID"))?.cmsForm?.impact_change_id === null), "cmsDetData597")
    let disableCondition;
    if (isEditCms) {
        disableCondition = userSGID !== cmsDetData?.cmsForm?.user_id;
    }
    //console.log("impactdattta", impactData)
    return (
        <>
            <DialogTitle>The Impact of Change</DialogTitle>
            <img
                src={BackIcon}
                alt="menu icon"
                style={{ width: "28px", height: "24px", cursor: "pointer", marginLeft: "13px" }}
                onClick={onBack}
            />
            <DialogContent>
                {/* <Header pageTitle="The Impact of Change" /> */}
                <Typography style={{ fontSize: 18, color: "#B51849", fontWeight: 600 }}>
                    Impact Change to be Considered
                </Typography>
                {initialLoad ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "45px"
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <React.Fragment>
                        {questionAssess.map((question, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginBottom: "5px",
                                    backgroundColor: "#F9F9F9",
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: "5px",
                                        flexShrink: 0,
                                        fontWeight: 550,
                                        backgroundColor: "#F9F9F9",
                                    }}
                                >
                                    {index + 1}
                                </span>
                                <Grid style={{ backgroundColor: "#F9F9F9", width: "100%" }}>
                                    <Typography style={{ ...CPQuestionsStyle, margin: 0 }}>
                                        {question.questions}
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            value={
                                                impactData.affects
                                                && impactData.affects[index] || null
                                            }
                                            onChange={(event) =>
                                                handleRadioChange(index, event.target.value)
                                            }
                                            row
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio sx={{ color: "#B51849" }} />}
                                                label="Yes"
                                                disabled={disableCondition}
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio sx={{ color: "#B51849" }} />}
                                                label="No"
                                                disabled={disableCondition}
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                    {impactData.affects[index] === "Yes" && (
                                        <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <InputLabel
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Actions *
                                                </InputLabel>
                                                <TextField
                                                    sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                    id="outlined-multiline-static"
                                                    value={impactData.actions[index] || ""}
                                                    onChange={(e) => onHandleChange(e, "actions", index)}
                                                    error={stepError && !impactData.actions[index]}
                                                    disabled={disableCondition}
                                                    autoComplete="off"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3}>
                                                <InputLabel
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Responsibility *
                                                </InputLabel>
                                                <TextField
                                                    sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                    id="outlined-multiline-static"
                                                    value={impactData.responsibility[index] || ""}
                                                    onChange={(e) => onHandleChange(e, "responsibility", index)}
                                                    error={stepError && !impactData.responsibility[index]}
                                                    disabled={disableCondition}
                                                    autoComplete="off"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3}>
                                                <InputLabel
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Date *
                                                </InputLabel>
                                                <TextField
                                                    sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                    id={`date-${index}`}
                                                    type="date"
                                                    value={impactData.date[index]}
                                                    onChange={(e) => handleDateChange(e, index)}
                                                    error={stepError && !impactData.date[index]}
                                                    variant="outlined"
                                                    InputLabelProps={{ sx: { fontWeight: 500, fontSize: 14 } }}
                                                    inputProps={{ max: new Date().toISOString().split("T")[0] }}
                                                    disabled={disableCondition}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3}>
                                                <InputLabel
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Remarks *
                                                </InputLabel>
                                                <TextField
                                                    sx={{ width: '100%', mt: 1, background: '#FFFFFF', borderRadius: 1 }}
                                                    id="outlined-multiline-static"
                                                    value={impactData.remarks && impactData.remarks[index] || ""}
                                                    onChange={(event) => handleRemarksChange(index, event)}
                                                    error={stepError && !impactData.remarks[index]}
                                                    disabled={disableCondition}
                                                    autoComplete="off"
                                                />
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: "5px" }}>
                                                <Box display="flex" alignItems="center">
                                                    <input
                                                        id="upload-image"
                                                        multiple
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        ref={fileInputRef}
                                                        disabled={disableCondition}
                                                    />
                                                    {/* <img
                                                        src={CloudUpload}
                                                        alt="Upload File"
                                                        style={{ width: "35px", height: "35px", cursor: "pointer" }}
                                                        onClick={() => handleCaptureGallery(index)} // Call the function to handle file upload
                                                    /> */}
                                                    <Button
                                                        variant="outlined"
                                                        component="span"
                                                        onClick={() => handleCaptureGallery(index)}
                                                        disabled={impactData.document && impactData.document[index] && impactData.document[index].length >= 5}
                                                    >
                                                        Upload Document
                                                    </Button>
                                                    {impactData && impactData.document && Array.isArray(impactData.document) && impactData.document[index] && (
                                                        <div style={{ marginTop: '10px' }}>
                                                            {impactData.document[index].map((file, fileIndex) => (
                                                                <div key={fileIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                                    <CloudDownloadIcon
                                                                        style={{ width: '35px', height: 'auto', marginRight: '10px', cursor: "pointer" }}
                                                                        onClick={() => handleDownloadDocument(file)} // Add download functionality
                                                                    />
                                                                    <span>{file}</span>
                                                                    <button
                                                                        onClick={() => handleDelete(index, fileIndex)} // Pass the correct index for deletion
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            background: 'none',
                                                                            border: 'none',
                                                                            color: 'red',
                                                                            fontSize: '20px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </Box>
                                                {!disableCondition && impactData.document && impactData.document[index] && impactData.document[index].length >= 5 && (
                                                    <Typography variant="subtitle2" color="error">
                                                        Allows maximum 5 documents to upload
                                                    </Typography>
                                                )}
                                            </Grid>

                                        </Grid>
                                    )}

                                </Grid>

                            </div>
                        ))}
                    </React.Fragment>
                )}
                {errors ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please fill all the fields
                    </Typography>
                ) : null}
                {saveMessage ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please Save before going to Next
                    </Typography>
                ) : null}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px"
                    }}
                >
                    {initialLoad ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : showSuccessPopup ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <p>Data saved successfully!</p>
                            </div>
                        </div>
                    ) : null}
                    {initialLoad ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : showUpdatePopup ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <p>Data updated successfully!</p>
                            </div>
                        </div>
                    ) : null}
                    {/* {initialLoad ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <CircularProgress />
                        </div>
                    </div>
                ) : showSubmitPopup ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <p>CMS No: {cmsNumberData} created successfully!</p>
                        </div>
                    </div>
                ) : null} */}
                    {initialLoad ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : showResubmitPopup ? (
                        <div className="popup-overlay">
                            <div className="popup">
                                <p>CMS Resubmitted successfully!</p>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row", // Keep it as row to align buttons horizontally
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    {(isEditCms && !(disableCondition) && (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.impact_change_id !== null) ?
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#fff",
                                fontSize: 20,
                                color: "#B1174B",
                                border: "1px solid #B1174B",
                                textTransform: "none",
                                width: "75%",
                                marginTop: 20,
                                marginRight: 5,
                                marginBottom: 10,
                                boxShadow: "none"
                            }}
                            onClick={handleImpactUpdate}
                            disabled={disableCondition}
                        >
                            Update
                        </Button>
                        :
                        ((!isEditCms || (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.impact_change_id === null) &&
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#fff",
                                    fontSize: 20,
                                    color: "#B1174B",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginRight: 5,
                                    marginBottom: 10,
                                    boxShadow: "none"
                                }}
                                onClick={handleImpactSave}
                            >
                                Save
                            </Button>
                        )}

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#B1174B",
                            fontSize: 20,
                            color: "white",
                            textTransform: "none",
                            width: "75%",
                            marginTop: 20,
                            marginRight: 5,
                            marginBottom: 10
                        }}
                        onClick={handleImpactNext}
                    >
                        Next
                    </Button>
                </div>
            </DialogContent>
        </>
    )
};

export default ImpactChange;
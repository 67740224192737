import React, { useState, useEffect } from "react";
import Header from "../app/TopAppBar";
import { InputLabel, TextField, MenuItem, Button, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { useRecoilState } from "recoil";
import { masterData, editMocData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";
import configParam from "../config";
import { useNavigate } from "react-router-dom";

const NewMasterData = ({ isOpen, onClose }) => {
    const [masterList, setMasterList] = useRecoilState(masterData);
    const [errors, setErrors] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [save, setSave] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [phoneNoError, setPhoneNoError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isEditMoc] = useRecoilState(editMocData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };
        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopup(setShowPopup, 1000);
                }, 2000);
            }
        };
        handlePopups(showSuccessPopup, setShowSuccessPopup);
        handlePopups(showUpdatePopup, setShowUpdatePopup);
    }, [showSuccessPopup, showUpdatePopup]);

    // const onHandleChange = (event, field) => {
    //     let updatedForm = { ...masterList };
    //     const value = event.target.value;
    //     updatedForm[field] = value;
    //     setMasterList(updatedForm);
    // };

    const onHandleChange = (e, field) => {
        const value = e.target.value;
        setMasterList(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleMasterSaveClick = async () => {
        try {
            const masterEntries = [];

            if (masterList.department) {
                masterEntries.push({
                    master_data: masterList.department,
                    tag: 'department',
                });
            }

            if (masterList.plant) {
                masterEntries.push({
                    master_data: masterList.plant,
                    tag: 'plant',
                });
            }

            if (masterList.customer) {
                masterEntries.push({
                    master_data: masterList.customer,
                    tag: 'customer',
                });
            }

            if (masterList.model) {
                masterEntries.push({
                    master_data: masterList.model,
                    tag: 'model',
                });
            }

            if (masterList.glass_reference) {
                masterEntries.push({
                    master_data: masterList.glass_reference,
                    tag: 'glass_reference',
                });
            }

            const responses = await Promise.all(masterEntries.map(entry => {
                return axios.post(API.INSERT_CMS_MASTER_DATA, entry, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });
            }));

            if (responses.every(response => response.status === 200)) {
                setSave(true);
                setShowSuccessPopup(true);
                setTimeout(() => {
                    onClose();
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error("An error occurred in saving master masterList:", error);
        }
    };

    const handleMasterCancelClick = () => {
        onClose();
    }

    return (
        <>
            <Dialog open={isOpen}
                onClose={onClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="sm">
                <DialogTitle>New Master Data</DialogTitle>
                <DialogContent>
                    <div style={{ background: "#F7F7F7", minHeight: "90vh" }}>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Department
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={masterList.department}
                                onChange={(e) => onHandleChange(e, "department")}
                            />
                        </div>

                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Plant
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={masterList.plant}
                                onChange={(e) => onHandleChange(e, "plant")}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Customer
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="customer"
                                value={masterList.customer}
                                onChange={(e) => onHandleChange(e, "customer")}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Model
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="model"
                                value={masterList.model}
                                onChange={(e) => onHandleChange(e, "model")}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <InputLabel
                                style={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#000",
                                    fontSize: 14,
                                }}
                            >
                                Glass Reference
                            </InputLabel>
                            <TextField
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    background: "#FFFFFF",
                                    borderRadius: 4,
                                }}
                                id="outlined-multiline-static"
                                value={masterList.glass_reference}
                                onChange={(e) => onHandleChange(e, "glass_reference")}
                            />
                        </div>

                        {errors ? (
                            <Typography variant="subtitle2" color="red">
                                {""}
                                Please fill all the fields
                            </Typography>
                        ) : null}
                        {initialLoad ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : showSuccessPopup ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <p>Data added successfully!</p>
                                </div>
                            </div>
                        ) : null}
                        {initialLoad ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : showUpdatePopup ? (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <p>Data updated successfully!</p>
                                </div>
                            </div>
                        ) : null}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row", // Keep it as row to align buttons horizontally
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 10
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#fff",
                                    fontSize: 20,
                                    color: "#B1174B",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginBottom: 10,
                                    marginRight: 5,
                                    boxShadow: "none",
                                }}
                                onClick={handleMasterSaveClick}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#B1174B",
                                    fontSize: 20,
                                    color: "#fff",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginBottom: 10,
                                    boxShadow: "none",
                                }}
                                onClick={handleMasterCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );

};

export default NewMasterData;